import React from 'react';

// Hooks
import { useMemo, useCallback, useState, useEffect, useContext } from 'react';
import { useWeek } from 'hooks/dates/useWeek';
import { useAPI } from 'hooks/useAPI';
import { useSearch } from 'hooks/useSearch';
import { useQuery } from 'hooks/useQuery';
import { useMemoCompare } from 'hooks/useMemoCompare';

// Components
import { NoteCellWeeklyView } from 'components/notes/list';
import { FilterBar } from 'components/notes/filter_bar'; 
import { Skeleton } from 'components/core/skeleton';
import { DailyEventsView } from 'components/tools/daily_events';
import { Error } from 'components/core/typo';
import { SlideOver } from 'components/core/slide_over';
import { NoteExtendedViewQueryBased } from 'components/notes/card';

// Contexts
import { SearchNotesContext, SearchNotesContextProvider } from 'contexts/search_notes';

// Utils
import { datesAreInInterval }  from 'utils/date_compare';
import { dateParse, parseWithTimeZone } from 'utils/locale';
import { timeMonday, timeDay} from 'd3-time';
import { ascending } from 'd3-array';

// API
import { countNotes, searchNotes } from 'api/notes';

export function ControlledNotesCard({notes, numOpenNotes, loading, error}){
  const {filters, dispatchFilters} = useContext(SearchNotesContext);

  const {query, setQuery} = useQuery();
  const [open, setOpen] = useState(query.has("noteId"));

  useEffect(()=>{
    if (!open){setQuery()}
  }, [open])

  const [date, setDate] = useState((query.has("date") && new Date(query.get("date"))) || filters.date || new Date());
  const windowDates = useWeek(date, {floorInput:true, offsetInput: -2, weeks:4}); // Set offset for lazy loading (buffer)

  useEffect(()=>{
    if (!datesAreInInterval(windowDates.fromDate, filters.fromDate, {step: 'day'})){
      dispatchFilters({type: 'dates', value: {...windowDates, date}})
    }
  }, [date, windowDates]);

  // This is an approximation of the displayed dates...
  const displayedWindowDates = useMemo(()=>({fromDate:timeMonday.floor(date), toDate:timeDay.offset(timeMonday.floor(date), 5)}), [date])

  const events = useMemo(()=> {
    if (!notes) return;
    return notes.map(d=>{
      const openAt = parseWithTimeZone(d.opened_at)
      const latestCommentDate = d.comments.length>0 && dateParse(d.comments[0].display_date);
      var date = openAt;
      var open = true;
      if ((openAt>displayedWindowDates.toDate || openAt<displayedWindowDates.fromDate) && latestCommentDate){
        date = latestCommentDate;
        open = false;
      }
      return {date: date, 
              latestCommentDate: latestCommentDate,
              color: d.code.color, 
              content: {...d,
                highlighted: query.get("noteId")===d.id,
                open}
              }
      })
  }, [displayedWindowDates,query, notes]);

  const rightBar = <FilterBar activePairing noteGroup noteCode numOpenNotes={numOpenNotes}/>;
  if (!notes){
    return <Skeleton.List numElements={1} itemClassName="h-32" className="space-y-2"/>
  }
 
  return <><DailyEventsView date={date} 
                            onDateChange={setDate} 
                            events={events} 
                            loading={loading}
                            sortBy={(a,b)=>{
                              return (a?.content?.student?.ni===b?.content?.student?.ni? ascending(a.latestCommentDate, b.latestCommentDate): ascending(a.content.student.name, b.content.student.name))
                            }}
                            layout="full" 
                            className="w-full" 
                            floorToMonday={true}
                            rightHeader={rightBar}
                            EventComponent={(d)=><NoteCellWeeklyView {...d} 
                            select={()=>{setOpen(true); setQuery({noteId: d.id});}}/>}
                            />
           {error? <Error.Text className="mt-3" {...error}/>: null}
            <SlideOver open={open} setOpen={setOpen} size="xl3">
            <NoteExtendedViewQueryBased/>
           </SlideOver>
          </>
}

export function WeeklyListNotesCardWithContext(){
  const {filters} = useContext(SearchNotesContext);

  const validateParams = useCallback(({fromDate, toDate})=>{
    if (!fromDate || !toDate) return false;
    return true;
  }, []);
  const fixedFilters = useMemoCompare(filters, (prev, next)=>(
    prev && next &&
    prev.orderDirection == next.orderDirection &&
    prev.orderBy == next.orderBy &&
    prev.fromDate == next.fromDate &&
    prev.toDate == next.toDate &&
    prev.isOpen == next.isOpen &&
    prev.hasActivePairing == next.hasActivePairing &&
    prev.includeNotes == next.includeNotes &&
    prev.shouldDiscuss == next.shouldDiscuss &&
    prev.active == next.active &&
    prev.group == next.group &&
    (prev.studySession && prev.studySession.id)===(next.studySession && next.studySession.id) &&
    (prev.preset && prev.preset.id)===(next.preset && next.preset.id) &&
    (prev.pairing && prev.pairing.id)===(next.pairing && next.pairing.id) &&
    (prev.tutor && prev.tutor.ni)===(next.tutor && next.tutor.ni) &&
    (prev.student && prev.student.ni)===(next.student && next.student.ni) &&
    (prev.team && prev.team.slug)===(next.team && next.team.slug) &&
    (prev.school && prev.school.slug)===(next.school && next.school.slug) &&
    (prev.schoolYear && prev.schoolYear.slug)===(next.schoolYear && next.schoolYear.slug) &&
    (prev.code && prev.code.code)===(next.code && next.code.code) 
    )
  );
  const [notes, { error, loading }] = useSearch(searchNotes, fixedFilters, {validateParams, limit: 500}); // Make sure we have all
  const openNotesFilters = useMemo(()=>({...fixedFilters,
                                        isOpen:true, 
                                        shouldDiscuss: null,
                                        fromDate: null, 
                                        toDate: null,
                                        code: null, 
                                        group: null}), [fixedFilters])

   const params = useMemoCompare(openNotesFilters, (prev, next)=>(prev && 
                                          next && JSON.stringify(prev) === JSON.stringify(next)));                                     
  const [numOpenNotes] = useAPI(countNotes, params, {immediate: false});
  return <ControlledNotesCard notes={notes} numOpenNotes={numOpenNotes} error={error} loading={loading}/>
}

export function NotesCard(){
  return <SearchNotesContextProvider>
          <WeeklyListNotesCardWithContext/>
        </SearchNotesContextProvider>
}