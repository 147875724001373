/* eslint-disable no-unused-vars */
import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useCallback } from 'react';
import { useSearchParams } from "react-router-dom";
import { useAPI } from 'hooks/useAPI';
import { useUser } from 'hooks/useUser';
import { FiShield, FiRadio } from 'react-icons/fi';
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSlot,
} from "@/components/ui/input-otp"

// API
import { answerSMSChallenge, resendChallenge } from 'api/auth';

// Components
import { Button } from 'components/core/button'
import { Error, RawTextWarning } from 'components/core/typo'
import { Navigate } from 'react-router-dom';
import { Label } from '@/components/ui/label';

export function SMSChallengeForm() {
    const { t } = useTranslation(["auth"]);
    const [answer, setAnswer] = useState();
    const [hasResendCode, setHasResendCode] = useState();
    const [, setUser] = useUser();
    const [searchParams] = useSearchParams();
    const params = useMemo(() => ({ token: searchParams.get("token"), answer }), [answer, searchParams]);
    const [result, { loading, error, execute }] = useAPI(answerSMSChallenge, params,
        {
            onResult: setUser,
            immediate: false
        });
    const [, resend] = useAPI(resendChallenge, { token: searchParams.get("token") }, { onResult: setHasResendCode, immediate: false })

    const onSubmit = useCallback((e) => {
        if (e) e.preventDefault();
        execute();
    }, [execute]);

    if (result) {
        if (searchParams.has("ref")) {
            return <Navigate to={searchParams.get("ref")} state={result} />
        }
        return <Navigate to="/" state={result} />
    }
    return <div className="space-y-6">
        <div className='relative h-36 w-36 bg-orange-100 text-orange-700 p-5 rounded-full'>
            <FiRadio className="absolute w-12 h-12 ml-7 mt-6" />
            <FiShield className="w-full h-full" />
        </div>
        <div>
            <h1 className="text-lg text-gray-800 font-medium">{t('sms-challenge.title')}</h1>
            <p className="text-base text-gray-500">{t('sms-challenge.description')}</p>
        </div>
        <Button color="inlineLink" size="link" loading={resend?.loading} onClick={resend?.execute}>
            {t("sms-challenge.resend-code")}
        </Button>
        {hasResendCode ? <RawTextWarning>{t("sms-challenge.resend-code-success")}</RawTextWarning> : null}
        <form onSubmit={onSubmit} disabled={!answer}>
            <Label>{t("sms-challenge.label")}</Label>
            <InputOTP maxLength={6}  value={answer} onChange={setAnswer}>
                <InputOTPGroup className='w-full'>
                    <InputOTPSlot className='h-12 w-full text-lg' index={0} />
                    <InputOTPSlot className='h-12 w-full text-lg' index={1} />
                    <InputOTPSlot className='h-12 w-full text-lg' index={2} />
                    <InputOTPSlot className='h-12 w-full text-lg' index={3} />
                    <InputOTPSlot className='h-12 w-full text-lg' index={4} />
                    <InputOTPSlot className='h-12 w-full text-lg' index={5} />
                </InputOTPGroup>
            </InputOTP>

            <Button disabled={!answer} color="active" block size="xl" className={"mt-3"} loading={loading} type="submit">
                {t("sms-challenge.submit")}
            </Button>
        </form>
        <Button color="inlineLink" size="link" href="/login">
            {t("sms-challenge.back-to-login")}
        </Button>
        {resend.error ? <Error.Text {...resend.error} /> : null}
        {error ? <Error.Text {...error} /> : null}
    </div>
}
