import React from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FiX, FiMenu } from 'react-icons/fi';
import { Button } from 'components/core/button';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ScrollArea } from '@/components/ui/scroll-area';

/*
  Mobile nav breakdown: md
*/
function Nav({ children }) {

    return <div className="z-50 hidden overflow-x-hidden overflow-y-hidden transition-all duration-500 bg-gray-800 md:flex hover:md:overflow-y-auto md:w-12 2xl:w-64 md:flex-col drop-shadow-xl md:hover:w-64 md:fixed md:inset-y-0">
        <ScrollArea>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-1 min-h-0 bg-gray-800 ">
                <Link to={"/"}>
                    <div className="items-center flex-shrink-0 hidden h-16 px-4 mt-6 2xl:block">
                        <img src="/assets/logo_transparent.png" className="absolute h-auto max-w-full left-10 max-h-14" />
                    </div>
                </Link>
                <div className="flex flex-col flex-1 group">
                    {children}
                </div>
            </div>
        </ScrollArea>
    </div>
}

function MobileNav({ children }) {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return <div className="md:hidden">
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-gray-800">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute top-0 right-0 pt-2 pr-2">
                                <button
                                    type="button"
                                    className="flex items-center justify-center w-10 h-10 ml-1 text-white rounded-full focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white"
                                    onClick={() => setSidebarOpen(false)}
                                >
                                    <span className="sr-only">Close sidebar</span>
                                    <FiX className="w-6 h-6 " aria-hidden="true" />
                                </button>
                            </div>
                        </Transition.Child>
                        <div className="flex items-center flex-shrink-0 px-4">
                            {/*Insert logo here h-8 w-auto*/}
                        </div>
                        <ScrollArea className='flex-1 h-0 mt-5 overflow-y-auto'>
                            {children}
                        </ScrollArea>
                    </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
            </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-12 2xl:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-1 min-h-0 bg-gray-800 border-r">
                <div className="items-center flex-shrink-0 hidden h-16 px-4 mt-6 2xl:block">
                    <img src="/assets/logo_transparent.png" className="h-auto max-w-full mx-auto max-h-14" />
                </div>
                <div className="flex flex-col flex-1 overflow-y-auto">
                    {children}
                </div>
            </div>
        </div>

        <div className="sticky top-0 z-10 flex flex-shrink-0 md:hidden ">
            <Button
                color="default"
                size="md"
                onClick={() => setSidebarOpen(true)}
            >
                <span className="sr-only">Open sidebar</span>
                <FiMenu className="w-5 h-5" aria-hidden="true" />
            </Button>
        </div>
    </div>
}
function Content({ children, className }) {
    return <div className={classnames("md:ml-12 2xl:ml-64 flex flex-col flex-1 bg-white z-10", className)}>
        {children}
    </div>
}

function Main({ children, className }) {
    return <main className={classnames("h-full max-h-full md:flex md:flex-col ", className)}>
        {children}
    </main>
}
function TopBar({ children }) {
    return <div className="sticky top-0 z-20 px-3 2xl:px-6 flex-0 flex items-center flex-shrink-0 h-12 bg-gray-100 border-b md:ml-12 2xl:ml-64">
        {children}
    </div>
}
export const SideBarLayout = { Nav, Content, Main, TopBar, MobileNav }

