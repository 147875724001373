import React from 'react';

// Components
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Button } from 'components/core/button';
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

// Hooks
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import classnames from 'classnames';
import { locale } from 'utils/locale';
import { timeMonth, timeDays, timeDay, timeWeek, timeWeeks } from 'd3-time';
import { group } from 'd3-array';

const dayFormat = locale.format("%d");
const fullDayFormat = locale.format("%d-%m-%Y");
const monthCalendarFormat = locale.format("%Y");

const dayToColor = (day, intervals) => {
    if (intervals) {
        for (var i = 0; i < intervals.length; i++) {
            if (day >= intervals[i].fromDate && day <= intervals[i].toDate) {
                if (intervals[i].className) return intervals[i].className;
                if (intervals[i].light) return "bg-gray-100 hover:bg-gray-200";
                if (intervals[i].blue) return "!bg-blue-500 text-white font-medium !hover:bg-blue-600";
                return "!bg-orange-500 text-white font-medium !hover:bg-orange-600";
            }
            if (fullDayFormat(day) === fullDayFormat(intervals[i].fromDate)) {
                if (intervals[i].className) return intervals[i].className;
                if (intervals[i].light) return "bg-gray-100 hover:bg-gray-200";
                if (intervals[i].blue) return "!bg-blue-500 text-white font-medium !hover:bg-blue-600";
                return "!bg-orange-500 text-white font-medium !hover:bg-orange-600";
            }
            if (fullDayFormat(day) === fullDayFormat(intervals[i].toDate)) {
                if (intervals[i].className) return intervals[i].className;
                if (intervals[i].light) return "bg-gray-100 hover:bg-gray-200";
                if (intervals[i].blue) return "!bg-blue-500 text-white font-medium !hover:bg-blue-600";
                return "!bg-orange-500 text-white font-medium !hover:bg-orange-600";
            }
        }
        return "bg-white hover:bg-gray-200"
    }
    return "bg-gray-50 text-gray-400 hover:bg-gray-100";
}
export function CompactCalendarMonth({ date, events, interval, intervals = [], onPreviousMonth, onNextMonth, minDateSelection, maxDateSelection, sparkline = false, onClick, className }) {
    const { t } = useTranslation("common");
    const weekDays = [t("short-week.sunday"),
    t("short-week.monday"),
    t("short-week.tueday"),
    t("short-week.wednesday"),
    t("short-week.thursday"),
    t("short-week.friday"),
    t("short-week.saturday")];

    const days = useMemo(() => {
        const d1 = timeWeek.offset(timeMonth.floor(date), -1);
        const d2 = timeMonth.ceil(timeWeek.offset(date, 1));
        const weeks = timeWeeks(d1, d2);
        return weeks.map(w => timeDays(w, timeWeek.offset(w, 1)));
    }, [date])

    const groupedEvents = useMemo(() => {
        const groupedEvents = group(events || [], d => fullDayFormat(d.date));
        return groupedEvents
    }, [events])

    const _intervals = interval ? [...intervals, interval] : intervals;


    return <div className={classnames("", className)}>
        {!sparkline && <div className="flex items-center">
            {onPreviousMonth ? <Button className="px-2 py-2 hover:bg-gray-100 rounded-full" color="none" layout="none" onClick={onPreviousMonth}><FiChevronLeft /></Button> : null}
            <div className={classnames("flex-1 text-center capitalize font-semibold", sparkline ? "text-base" : "text-lg")}>
                {t(`month.${timeMonth.floor(date).getMonth()}`)} {monthCalendarFormat(timeMonth.floor(date))}
            </div>
            {onNextMonth ? <Button className="px-2 py-2 hover:bg-gray-100 rounded-full" color="none" layout="none" onClick={onNextMonth}><FiChevronRight /></Button> : null}
        </div>}
        <div className={classnames("isolate grid grid-cols-7 text-sm", sparkline ? "mt-0" : "mt-4")}>
            {weekDays.map((weekDay, i) => <div className="font-normal uppercase text-xs text-center text-gray-500" key={i}>{weekDay}</div>)}
        </div>
        <div className="isolate mt-2 grid grid-cols-7 rounded-lg gap-px bg-gray-200 shadow  text-sm ring-1 ring-gray-200 ">
            {days.map((week, i) =>
                week.map((day, j) =>
                    <div
                        className={classnames("focus:z-10 relative py-0.5",
                            events && "",
                            (i === 0 && j === 0) ? "rounded-tl-lg" : "",
                            (i === 0 && j === week.length - 1) ? "rounded-tr-lg" : "",
                            (i === days.length - 1 && j === 0) ? "rounded-bl-lg " : "",
                            (i === days.length - 1 && j === week.length - 1) ? "rounded-br-lg" : "",
                            dayToColor(day, _intervals)
                            // ((_intervals && _intervals.length>0)?
                            //   _intervals.map((_int)=>
                            //     (day>=_int.fromDate && day<=_int.toDate)?
                            //     (_int.light? "bg-gray-100 hover:bg-gray-200": 
                            //     _int.blue?"!bg-blue-500 text-white font-medium !hover:bg-blue-600 ":

                            //     "!bg-orange-500 text-white font-medium !hover:bg-orange-600 "):
                            //     "bg-gray-50 text-gray-400 hover:bg-gray-100 "
                            //   ).join(" ")
                            //   :  "bg-white hover:bg-gray-200")
                            ,
                            onClick ? (minDateSelection && day <= minDateSelection) || (maxDateSelection && day >= maxDateSelection)
                                ? "cursor-not-allowed "
                                : "cursor-pointer"
                                : ""

                        )}
                        onClick={onClick && (!minDateSelection || day > minDateSelection) && (!maxDateSelection || day < maxDateSelection)
                            ? () => onClick(day)
                            : null}
                        key={`${i}-${j}`}>
                        <div className={classnames("mx-auto flex items-center justify-center ",
                            sparkline ? "w-2 text-xxs" : "w-full aspect-square",
                            !sparkline && fullDayFormat(new Date()) === fullDayFormat(timeDay.floor(day)) ? "border-2 border-blue-600"
                                : "",
                        )}
                        >
                            {!sparkline && dayFormat(day)}
                        </div>
                        <Fragment>
                            {!groupedEvents.get(fullDayFormat(timeDay.floor(day))) ? null :
                                groupedEvents.get(fullDayFormat(timeDay.floor(day))).length <= 4 ?
                                    <div className="flex justify-evenly bg-transparent absolute bottom-0 left-0 right-0 mb-1 items-center  " style={{ gridColumnStart: 2, gridRow: `${2} / span 1` }}>
                                        {groupedEvents.get(fullDayFormat(timeDay.floor(day))).map((e, j) => {
                                            var Badge = <span key={j} style={{ background: e.color || "black" }} className={classnames("h-2 w-full mx-0.5 rounded-full relative z-50", e.className)} />;
                                            if (e.tooltip) {
                                                return (
                                                    <TooltipProvider key={j}>
                                                        <Tooltip>
                                                            <TooltipTrigger>{Badge}</TooltipTrigger>
                                                            <TooltipContent {...e.tooltipProps}>{e.tooltip}</TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                );
                                            }
                                            return Badge;
                                        })}
                                    </div> :
                                    <div
                                        className="flex justify-evenly bg-transparent absolute bottom-0 left-0 right-0 mb-0 items-center "
                                        style={{ gridColumnStart: 2, gridRow: `${2} / span 1` }}
                                    >
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger>
                                                    <span
                                                        className="text-xs px-2 text-white rounded-md py-0.25 mb-0 block"
                                                        style={{
                                                            background:
                                                                groupedEvents.get(fullDayFormat(timeDay.floor(day)))[0].color || "black",
                                                        }}
                                                    >
                                                        {groupedEvents.get(fullDayFormat(timeDay.floor(day))).length}+
                                                    </span>
                                                </TooltipTrigger>
                                                <TooltipContent className=''>
                                                    {t("calendar-events-tooltip", {
                                                        count: groupedEvents.get(fullDayFormat(timeDay.floor(day))).length,
                                                    })}
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                            }
                        </Fragment>
                    </div>
                )
            )}

        </div>

    </div>
}
