/* eslint-disable */
import React, { useMemo, useState } from "react";

// Components
import { Button } from "components/core/button";
import { InfoSectionHeading } from "components/core/typo";
import { Table } from "components/core/table";
import { Modal, ModalBody, ModalTitle } from "components/core/modal";
import { TextInput } from "components/core/inputs";
import { Label } from "components/tools/modifiable_fields";

// API
import { createOrUpdateIndividualMeetingDate, deleteIndividualMeetingDate } from "api/meeting_dates";

// Hooks
import { useTranslation } from "react-i18next";
import { useAPI } from "hooks/useAPI";
import { useToggleState } from "hooks/useToggleState";
import { SearchUser } from "components/tools/search_user";

export function AddButton({onResult, meetingPeriod}){
    const { t } = useTranslation("common");
    const [open, toggle] = useToggleState(false);
    return <>
        <Button size="md" color="active" className="h-fit w-fit" onClick={toggle}>
            {t("individual-meeting-setups.add-button")}
        </Button>
        <AddIndividualMeetingSetup 
            open={open}
            setOpen={toggle}
            onResult={onResult}
            meetingPeriod={meetingPeriod}
        />
    </>
}

export function EditButton({onResult, meetingPeriod, defaultValue}) {
    const { t } = useTranslation("common");
    const [open, toggle] = useToggleState(false);
    return <>
        <Button onClick={toggle} size="xs" color="gray">{t("modify")}</Button>
        <EditIndividualMeetingSetup
            open={open}
            setOpen={toggle}
            onResult={onResult}
            meetingPeriod={meetingPeriod}
            defaultValue={defaultValue}
        />
    </>
}

export function DeleteButton({onResult, id}) {
    const { t } = useTranslation("common");
    const [open, toggle] = useToggleState(false);
    return <>
        <Button onClick={toggle} size="xs" color="delete">{t("delete")}</Button>
        <DeleteIndividualMeetingSetup
            open={open}
            setOpen={toggle}
            onResult={onResult}
            id={id}
        />
    </>
}

export function AddIndividualMeetingSetup({ open, setOpen, onResult, meetingPeriod }) {
    const { t } = useTranslation("common");
    const [meetingGoal, setMeetingGoal] = useState("");
    const [user, setUser] = useState(null);
    const validateParams = (p) => {
        return p.user && p.meetingGoal !== null && meetingPeriod;
    };
    const reset = () => {
        setUser(null);
        setMeetingGoal("");
    };
    const handleOpen = () => {
        setOpen();
        reset();
    };
    const handleResult = (res) => {
        onResult?.(res);
        reset();
        setOpen(false);
    };
    const [,{execute, loading}] = useAPI(createOrUpdateIndividualMeetingDate, {user, meetingGoal: Number(meetingGoal), meetingPeriod}, {immediate: false, validateParams, onResult: handleResult});
    
    return <>
        <Modal open={open} setOpen={handleOpen}>
            <ModalTitle>{t("individual-meeting-setups.add-title")}</ModalTitle>
            <ModalBody>
                <div className="grid gap-2 mb-3 md:grid-cols-5 grid-cols-1">
                    <div className="w-full col-span-3">
                        <Label>{t("target-user")}</Label>
                        <SearchUser value={user} setValue={setUser} />
                    </div>
                    <div className="w-full col-span-2 ">
                        <Label>{t("meeting-goal-per-week")}</Label>
                        <TextInput
                            className=""
                            type="number"
                            step="1"
                            max="100"
                            min="0"
                            placeholder={t("add-meeting-date.score-placeholder")}
                            size="sm"
                            value={meetingGoal}
                            onChange={setMeetingGoal}
                        />
                    </div>
                </div>
            </ModalBody>
            <div className="flex gap-2">
                <Button block color="gray" size="md" onClick={()=>setOpen(false)}>{t("cancel-short")}</Button>
                <Button block color="active" loading={loading} disabled={!(user && meetingGoal !== "" && meetingPeriod)} size="md" onClick={execute}>{t("save")}</Button>
            </div>
        </Modal>
    </>
}

export function EditIndividualMeetingSetup({ open, setOpen, onResult, meetingPeriod, defaultValue }) {
    const { t } = useTranslation("common");
    const {user, meeting_goal:defaultGoal} = defaultValue || {};
    const [meetingGoal, setMeetingGoal] = useState(defaultGoal || "");
    const validateParams = (p) => {
        return p.user && p.meetingGoal !== null && meetingPeriod;
    };
    const handleOpen = (v) => {
        if (v) setMeetingGoal(defaultGoal || "")
        setOpen(v);
    }
    const handleResult = (res) => {
        onResult?.(res);
        setOpen(false);
    };
    const [,{execute, loading}] = useAPI(createOrUpdateIndividualMeetingDate, {user, meetingGoal, meetingPeriod}, {immediate: false, validateParams, onResult: handleResult});
    return <>
        <Modal open={open} setOpen={handleOpen}>
            <ModalTitle>{t("individual-meeting-setups.add-title")}</ModalTitle>
            <ModalBody>
                <div className="grid gap-2 mb-3 md:grid-cols-5 grid-cols-1">
                    <div className="w-full col-span-3">
                        <Label>{t("target-user")}</Label>
                        <SearchUser disabled value={user} />
                    </div>
                    <div className="w-full col-span-2 ">
                        <Label>{t("meeting-goal-per-week")}</Label>
                        <TextInput
                            className=""
                            type="number"
                            step="1"
                            max="100"
                            min="0"
                            placeholder={t("add-meeting-date.score-placeholder")}
                            size="sm"
                            value={meetingGoal}
                            onChange={setMeetingGoal}
                        />
                    </div>
                </div>
            </ModalBody>
            <div className="flex gap-2">
                <Button block color="gray" size="md" onClick={()=>handleOpen(false)}>{t("cancel-short")}</Button>
                <Button block color="active" loading={loading} disabled={!(user && meetingGoal !== "" && meetingPeriod)} size="md" onClick={execute}>{t("save")}</Button>
            </div>
        </Modal>
    </>
}

export function DeleteIndividualMeetingSetup({open, setOpen, onResult, id}){
    const { t } = useTranslation("common");
    const handleResult = () => {
        onResult?.(id);
        setOpen(false);
    };
    const [,{execute, loading}] = useAPI(deleteIndividualMeetingDate, {id}, {immediate: false, onResult:handleResult});
    return <>
        <Modal open={open} setOpen={setOpen}>
            <ModalTitle>{t("individual-meeting-setups.delete-title")}</ModalTitle>
            <ModalBody className="text-red-500 py-2">
            {t("individual-meeting-setups.delete-warning")}
            </ModalBody>
            <div className="flex gap-2">
                <Button block color="gray" size="md" onClick={()=>setOpen(false)}>{t("cancel-short")}</Button>
                <Button block color="deleteContrast" loading={loading} disabled={loading} size="md" onClick={execute}>{t("delete")}</Button>
            </div>
        </Modal>
    </>
}

export function IndividualMeetingSetups({ value, setValue, meetingPeriod }) {
    const { t } = useTranslation("common");
    const handleDelete = (rule) => {
        setValue(value.filter((r) => r.id !== rule.id));
    };
    const handleAdd = (rule) => {
        const exists = value.find((r) => r.user.id === rule.user.id);
        if (exists) {
            setValue(value.map((r) => (r.user.id === rule.user.id ? rule : r)));
        }
        else {
            setValue([...value, rule]);
        }
    };
    const handleUpdate = (rule) => {
        setValue(value.map((r) => (r.id === rule.id ? rule : r)));
    };
    const headers = useMemo(
        () => [
            {
                title: t("name"),
                itemClassName: "!py-2",
                field: (d) => d?.user?.name || `${d?.user?.firstname} ${d?.user?.lastname}`
            },
            {
                title: t("meeting-goal-per-week"),
                field: (d) => d?.meeting_goal
            },
            {
                headerId: "modify",
                field: (d) => d,
                itemClassName: "text-right !px-0",
                FormatComponent: (d)=> <EditButton 
                    defaultValue={d} 
                    onResult={handleUpdate} 
                    meetingPeriod={meetingPeriod} 
                />
            },
            {
                headerId: "delete",
                field: (d) => d,
                itemClassName: "text-right !px-0",
                FormatComponent: (d)=> <DeleteButton
                    id={d.id}
                    onResult={()=>handleDelete(d)}
                />
            }
        ],
        [value]
    );

    return <>
        <div>
            <div>
                
                <div className="flex justify-between items-center flex-wrap">
                    <InfoSectionHeading smallTitle={t("individual-meeting-setups.title")} className="pb-0" />
                    <AddButton 
                        onResult={handleAdd} 
                        meetingPeriod={meetingPeriod} 
                    />
                </div>
                <InfoSectionHeading longDescription={t("individual-meeting-setups.description")} />
                
            </div>
            <Table
                data={value}
                headers={headers}
                indexingKey={"id"}
                emptyState={t("individual-meeting-setups.empty-state")}
            />
        </div>
    </>
}