/*eslint-disable */
import React, { useMemo, useContext, useState, useRef } from "react";

// Components
// import { Table } from "components/core/table";
import { TutorsContext } from "contexts/tutors"
// import { SectionHeading } from "components/core/typo";
import { Skeleton } from "components/core/skeleton";
import { SearchContext } from 'contexts/search';
import { ListSearch } from "components/core/inputs";
import { Spinner } from "components/core/spinner";
import { Paginate } from "components/core/paginate";
import { FiFileText, FiTrash } from 'react-icons/fi';
import { Assignation } from "components/recruting/inscriptions/assignation";
import { BooleanPill, InscriptionStatus, findActiveInscription, Mails, isCompleted, hasValue } from "components/recruting/inscriptions";
import { SearchContextProvider } from "contexts/search";
import { TutorsContextProvider } from "contexts/tutors";
import { Button } from "components/core/button";
import { SelectMenu } from "components/tools/select-menu";
import { PreferenceCell } from "components/student/preferences";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Pill } from "@/components/core/pill";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";

// Icons
import { MagnifyingGlassIcon, } from '@heroicons/react/24/outline';

// Utils
import classNames from "classnames";
import { getCurrentPeriod } from "utils/period";

// Hooks
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/useUser";
import { NoteSliderOverContext } from "contexts/note-slider";
import { NoteCommentCard } from "components/notes/list";
// import { Tooltip } from "components/core/tooltip";
import { Tooltip, TooltipProvider, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { SearchUser } from "components/tools/search_user";
import { TutorSliderOverContext } from "contexts/tutor_slideover";
import { format, timeDay } from "d3";
import { dateParse, locale, parseWithTimeZone } from "utils/locale";
import { cronsCompact, hasDispo } from "utils/crons";
import { useScrollThreshold } from "@/hooks/useScrollThreshold";

function generateTextItems(fields, {
    formatTitle,
    formatValue = (d) => d,
    className = "",
    itemClassName,
    ...props
} = {}) {
    if (!fields) return [];
    if (typeof fields === 'string') fields = [fields];
    return fields.map((field) => {
        const [key, subkey] = field.split(".");
        return {
            field: (d) => d,
            title: formatTitle ? formatTitle(key) : key,
            className: "px-4",
            active: false,
            itemClassName: itemClassName || "px-4",
            ...props,
            FormatComponent: (d) => {
                return <div className={classNames(className, "")}>
                    {subkey ? formatValue(d[key]?.[subkey]) : formatValue(d[key])}
                </div>
            }
        }
    })
}

function InscriptionNote({ note }) {
    const { shouldOpenNote } = useContext(NoteSliderOverContext);

    return (
        <div
            key={note.id}
            className="relative"
            onClick={(e) => {
                shouldOpenNote(note.id);
                e.stopPropagation();
            }}
        >
            <TooltipProvider delayDuration={300}>
            <Tooltip>
                <TooltipTrigger>
                    <span className="relative cursor-pointer">
                        <FiFileText className="h-5 w-5 text-blue-500" />
                    </span>
                </TooltipTrigger>
                <TooltipContent>
                    <NoteCommentCard {...note} noBorder />
                </TooltipContent>
            </Tooltip>
            </TooltipProvider>
        </div>
    );
}

function InscriptionNotes({ tutor }) {
    const { t } = useTranslation("common");
    const notes = tutor?.inscription_notes || [];

    return (
        <div className="flex items-center gap-2">
            {notes.map((note) => (
                <InscriptionNote key={note.id} note={note} />
            ))}
            {notes.length === 0 && (
                <TooltipProvider delayDuration={300}>
                <Tooltip>
                    <TooltipTrigger>
                        <span className="relative cursor-pointer">
                            <FiFileText className="h-5 w-5 text-gray-300" />
                        </span>
                    </TooltipTrigger>
                    <TooltipContent className="max-w-72">{t("no-note-should-create-manually")}</TooltipContent>
                </Tooltip>
                </TooltipProvider>
            )}
        </div>
    );
}


function HasOnlineLunch({ schedule_availabilities, schools, online_preference, on_site_preference }) {
    /*
    Si un tuteur est en ligne : il faut qu'il ait au moins une disponibilité en semaine 
    (lundi au jeudi) d'une heure en soirée (entre 16h30 et 20h30). S'il a juste une 
    disponible le vendredi soir, ça ne fonctionne pas non plus. Il n'y a pas de tutorat le vendredi soir.
 
    Pour tous les autres tuteurs, la règle du vendredi soir s'applique aussi : Il faut 
    qu'ils auient au moins une autre disponibilité d'une heure sur l'heure du midi ou en soirée durant la semaine.

    Techniquement, il faudrait aussi une règle pour les tuteurs disponibles seulement à Pointe-Lévy. Il faudrait qu'ils
    aient au moins une disponibilité en soirée (entre 16h30 et 20h00 pour ceux en personne / hybride & entre 17h15 et 20h30 pour ceux en ligne)

    schedule_availabilities: List[objects] with from_cron, to_cron (cron == '0 17 * * 1-4' for example)
    */
    var isValid = true;
    const hasVanier = schools?.find((s) => s.slug === "ecole-secondaire-vanier");
    const hasPointeLevy = schools?.find((s) => s.slug === "ecole-secondaire-pointe-levy");
    const hasOnlyPointeLevy = hasPointeLevy && !hasVanier;

    const rules = [];
    if (online_preference) {
        const h0 = hasVanier ? "16:30" : "17:15";
        const h1 = "20:30";

        const hasEvening = schedule_availabilities?.find((s) =>
            hasDispo(s.from_cron, s.to_cron, ["Lundi", "Mardi", "Mercredi", "Jeudi"], h0, h1, 60)
        );
        rules.push("[En ligne]");
        rules.push(`Disponibilité en soirée (entre ${h0} et ${h1}) en semaine (lundi au jeudi)`);
        isValid = isValid && hasEvening;
    } else {
        const h0e = "16:30";
        const h1e = "20:00";
        const h0l = "11:30";
        const h1l = "12:30";

        const hasEvening = schedule_availabilities?.find((s) =>
            hasDispo(s.from_cron, s.to_cron, ["Lundi", "Mardi", "Mercredi", "Jeudi"], h0e, h1e, 60) ||
            (!hasOnlyPointeLevy &&
                hasDispo(s.from_cron, s.to_cron, ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"], h0l, h1l, 60))
        );
        rules.push(on_site_preference ? "[En personne]" : "[Hybride]");
        rules.push(`Disponibilité en soirée (entre ${h0e} et ${h1e}) en semaine (lundi au jeudi)`);
        if (!hasOnlyPointeLevy) {
            rules.push(`Disponibilité à l'heure du midi (entre ${h0l} et ${h1l}) en semaine (lundi au jeudi)`);
        }
        isValid = isValid && hasEvening;
    }

    const tooltipContent = (
        <div>
            {rules.map((r, i) => (
                <div key={i}>{r}</div>
            ))}
        </div>
    );

    return (
        <TooltipProvider>
        <Tooltip>
            <TooltipTrigger>
                <span className="relative">
                    <BooleanPill value={isValid} />
                </span>
            </TooltipTrigger>
            <TooltipContent>{tooltipContent}</TooltipContent>
        </Tooltip>
        </TooltipProvider>
    );
}

function UserInscriptionsWithContext({ hideCanAssign }) {
    const { t } = useTranslation("common");
    const { loading, paging, setPage, reload, augmentedTutors: unsortedTutors } = useContext(TutorsContext)
    const [sortingField, setSortingField] = useState("inscription-date");
    const [sortingOrder, setSortingOrder] = useState(false);
    const scrollRef = useRef();
    const isPastThreshold = useScrollThreshold(scrollRef, 10);
    const tutors = useMemo(() => (unsortedTutors || []).sort((a, b) => {
        var aobj, bobj;
        if (sortingField === "inscription-date") {
            // Do something else
            const insA = findActiveInscription(a?.active_periods || []);
            const insB = findActiveInscription(b?.active_periods || []);
            aobj = new Date(insA?.created_at);
            bobj = new Date(insB?.created_at);
        }
        else if (sortingField === "completed") {
            aobj = isCompleted(a);
            if (aobj && a.antecedent_judiciaire_document_sent && a.antecedent_judiciaire_document_validated) {
                aobj = 2;
            }
            bobj = isCompleted(b);
            if (bobj && b.antecedent_judiciaire_document_sent && b.antecedent_judiciaire_document_validated) {
                bobj = 2;
            }
        }
        else if (sortingField === "tutor") {
            aobj = a?.name;
            bobj = b?.name;
        }
        else if (sortingField === "emails") {
            aobj = a?.mails?.length;
            bobj = b?.mails?.length;
        }
        else if (sortingField === "notes") {
            aobj = a?.inscription_notes?.length;
            bobj = b?.inscription_notes?.length;
        }
        else {
            aobj = !!a[sortingField];
            bobj = !!b[sortingField];
        }
        if (sortingOrder) {
            return aobj > bobj ? 1 : -1;
        } else {
            return aobj > bobj ? -1 : 1;
        }
    }), [unsortedTutors, sortingField, sortingOrder]);

    const [group, setGroup] = useState("inscription");
    const { filters, dispatchFilters } = useContext(SearchContext);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    // const [user,] = useUser();
    const { shouldOpenTutor } = useContext(TutorSliderOverContext);
    const statusFields = [
        "contrat_engagement_document_received",
        "cyber_security_policy_document_received",
        "code_ethique_document_received",
        "--separator--1",
        "antecedent_judiciaire_document_received",
        "antecedent_judiciaire_document_conform",
        "antecedent_judiciaire_document_sent",
        "antecedent_judiciaire_document_validated",
        "--separator--2",
        "schedule_availabilities",
        "schools",
        "subjects",
        "has_tutoring_type",
        "--separator--3",
        "facebook_account",
        "facebook_friend_status",
        "facebook_group",
        "call_made",
        "formation_completed",
    ]
    const numCompleted = useMemo(() => tutors?.filter((d) => isCompleted(d) && (!d?.antecedent_judiciaire_document_validated || !d?.antecedent_judiciaire_document_sent)).length, [tutors]);
    const numNotCompleted = useMemo(() => tutors?.filter((d) => !isCompleted(d)).length, [tutors]);
    const numReadyForPairing = useMemo(() => tutors?.filter((d) => isCompleted(d) && d?.antecedent_judiciaire_document_validated && d?.antecedent_judiciaire_document_sent).length, [tutors]);

    const headers = useMemo(() => {
        const inscriptionStatusItems = [
            {
                field: (d) => d,
                title: t("emails"),
                className: "px-4",
                headerId: "emails",
                active: false,
                itemClassName: "px-4",
                FormatComponent: (d) => <div>
                    <Mails mails={d?.mails || []} />
                </div>
            },
            ...statusFields.map((field) => {
                if (field.startsWith("--separator--")) {
                    return {
                        field: field,
                        headerId: field,
                        className: "px-0",
                        itemClassName: "px-0 border-r",
                    }
                }
                if (field === "facebook_friend_status") {
                    return {
                        field: (d) => d,
                        title: t(`tutor-inscription-fields.${field}`),
                        info: t(`tutor-inscription-fields.${field}-info`),
                        headerId: field,
                        className: "px-4",
                        itemClassName: "px-4",
                        FormatComponent: (d) => <div className="">
                            <BooleanPill value={d[field]} />
                        </div>
                    }
                }
                if (field === "facebook_group") {
                    return {
                        field: (d) => d,
                        title: t(`tutor-inscription-fields.${field}`),
                        info: t(`tutor-inscription-fields.${field}-info`),
                        headerId: field,
                        className: "px-4",
                        itemClassName: "px-4",
                        FormatComponent: (d) => <div className="">
                            <BooleanPill value={d[field]} />
                        </div>
                    }
                }
                if (field === "call_made") {
                    return {
                        field: (d) => d,
                        title: t(`tutor-inscription-fields.${field}`),
                        info: t(`tutor-inscription-fields.${field}-info`),
                        headerId: field,
                        className: "px-4",
                        itemClassName: "px-4",
                        FormatComponent: (d) => <div className="">
                            <BooleanPill value={d[field]} showDate={true} />
                        </div>
                    }
                }
                if (field === "formation_completed") {
                    return {
                        field: (d) => d,
                        title: t(`tutor-inscription-fields.${field}`),
                        info: t(`tutor-inscription-fields.${field}-info`),
                        headerId: field,
                        className: "px-4",
                        itemClassName: "px-4",
                        FormatComponent: (d) => <div className="">
                            <BooleanPill value={d[field]} showDate={true} />
                        </div>
                    }
                }
                return {
                    field: (d) => d,
                    title: t(`tutor-inscription-fields.${field}`),
                    info: t(`tutor-inscription-fields.${field}-info`),
                    headerId: field,
                    className: "px-4",
                    itemClassName: "px-4",
                    FormatComponent: (d) => <div className="">
                        <BooleanPill value={hasValue(d[field])} />
                    </div>
                }
            }),

        ]
        const generalItems = [
            ...generateTextItems(["email", "phone", "city", "street", "zipcode", "gender", "occupation.name", "occupation_location", "occupation_program", "tutor_or_parent_is_immigrant", "tshirt_size"], { formatTitle: (v) => t("tutor-inscription-fields." + v) }),
        ]
        const preferencesItems = [
            ...generateTextItems("subjects", {
                formatTitle: (v) => t("tutor-inscription-fields." + v),
                itemClassName: "px-4  text-sm",
                formatValue: (d) => d?.map((s) => s.name).join(", ")
            }),
            ...generateTextItems("comment_on_prefered_subjects", { formatTitle: (v) => t("tutor-inscription-fields." + v) }),
            ...generateTextItems("schools",
                {
                    formatTitle: (v) => t("tutor-inscription-fields." + v),
                    itemClassName: "px-4 text-sm",
                    formatValue: (d) => d?.map((s) => s.short_name).join(", ")
                }),
            {
                field: (d) => d ? d.schedule_availabilities : 'schedule_availabilities',
                title: t("tutor-inscription-fields.schedule_availabilities"),
                className: "px-4",
                itemClassName: "px-4 min-w-[200px] text-sm",
                format: (d) => cronsCompact(d),

            }, {
                field: (d) => d ? d : 'lunch_schedule_availabilities',
                title: t("tutor-inscription-fields.lunch_schedule_availabilities"),
                className: "px-4",
                itemClassName: "px-4 min-w-[200px] text-sm",
                FormatComponent: (d) => <div className="">
                    <HasOnlineLunch {...d} />
                </div>
            },
            {
                field: (d) => d ? d : 'tutoring-type-pref',
                title: t("tutor-inscription-fields.tutoring_type"),
                className: "px-4",
                itemClassName: "px-4",
                FormatComponent: (d) => <div className="">
                    <PreferenceCell {...d} />
                </div>

            },
            ...generateTextItems(
                [
                    "student_with_troubles",
                    "precision_on_student_with_troubles",
                    "student_in_frencization_class",
                    "precision_on_student_in_frencization"
                ],
                {
                    formatTitle: (v) => t("tutor-inscription-fields." + v)
                }
            ),
        ]
        const recruitmentItems = generateTextItems(["how_have_you_heard", "how_have_you_heard_other"], { formatTitle: (v) => t("tutor-inscription-fields." + v) });
        const implicationsItems = [
            ...generateTextItems(["have_you_ever_been_tutor"], { formatTitle: (v) => t("tutor-inscription-fields." + v) }),
            ...generateTextItems(["active_periods"], {
                formatTitle: () => t("source"), formatValue: (d) => {
                    const source = findActiveInscription(d)?.source;
                    return source ? t(`inscription-sources.${source}`) : "";
                }
            }),
            ...generateTextItems(["why_get_in", "active_since"], { formatTitle: (v) => t("tutor-inscription-fields." + v) }),
            ...generateTextItems(["created_at"], { formatTitle: (v) => t("tutor-inscription-fields." + v), formatValue: (d) => new Date(d).toLocaleDateString('en-CA') }),
            ...generateTextItems(["active_periods"], { formatTitle: (v) => t("tutor-inscription-fields." + v), formatValue: (d) => [...new Set(d?.map((p) => p.name))].join(", ") }),
            ...generateTextItems(["retired_of_service_since", "reason_for_end_of_service"], { formatTitle: (v) => t("tutor-inscription-fields." + v) }),
        ]
        const commentsItems = generateTextItems(["comments", "form_comments"], { formatTitle: (v) => t("tutor-inscription-fields." + v) });
        const headerGroups = {
            inscription: inscriptionStatusItems,
            general: generalItems,
            preferences: preferencesItems,
            recruitment: recruitmentItems,
            implications: implicationsItems,
            comments: commentsItems
        }
        const items = [
            {
                field: (d) => d,
                title: t("inscription-date"),
                className: "!pl-5",
                active: false,
                itemClassName: "whitespace-nowrap !pl-5",
                FormatComponent: (d) => {
                    const inscription = findActiveInscription(d?.active_periods || []);
                    const formattedDate = inscription && locale.format("%d %b")(new Date(inscription?.created_at));
                    return <div>
                        <span className={"tabular-nums"}>{formattedDate}</span>
                        <span className={"text-gray-500 text-sm ml-1 "}>({t("num-days", { count: timeDay.count(parseWithTimeZone(inscription?.created_at), new Date()) })})</span>
                    </div>
                }
            },
            {
                field: (d) => d,
                title: t("completed"),
                className: "",
                headerId: "completed",
                active: false,
                itemClassName: "!pl-0",
                FormatComponent: (d) => <InscriptionStatus tutor={d} />
            },
            {
                field: (d) => d,
                title: t("notes"),
                headerId: "notes",
                className: "",
                active: false,
                itemClassName: "!pl-0",
                FormatComponent: (d) => <InscriptionNotes tutor={d} />
            },

            ...(headerGroups[group] || []),

        ]
        return items;
    }, [tutors, selected, group]);
    return <>
        {/* <SectionHeading title={t("tutors")} /> */}
        <div className="flex md:items-center justify-between border-b flex-col md:flex-row ">
            <div className="grid grid-cols-1 md:flex md:items-center gap-2 pl-2">
                <div className=" relative">
                    {
                        loading ?
                            <Spinner className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" /> :
                            <MagnifyingGlassIcon
                                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                    }
                    <ListSearch color="none"
                        noIcons
                        loading={loading}
                        search={true}
                        className="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder={t("search-by-name")}
                        onChange={(value) => dispatchFilters({ type: 'search', value })}
                    />

                </div>

                <FilterGroup
                    value={group}
                    setValue={setGroup}
                />
                {!hideCanAssign && <>
                    <Button color="gray" size="sm" onClick={() => setSelected(tutors)}>
                        {t("search-tutors.select-all")}
                    </Button>
                    <Button color="delete" disabled={!selected || selected.length === 0} size="toolbarIcon" onClick={() => setSelected([])}>
                        <FiTrash />
                    </Button></>}
            </div>
            <div className="grid grid-cols-1 md:flex md:items-center gap-2 p-2">
                {!hideCanAssign && <><div className="">
                    <Button color="gray" disabled={!selected || selected.length === 0} size="sm" onClick={() => setOpen(true)}>
                        {t("assignation.title")} ({selected.length})
                    </Button>
                    <Assignation
                        values={selected}
                        setValues={setSelected}
                        open={open}
                        setOpen={setOpen}
                        onAssign={() => reload?.()}
                    />
                </div></>}
                <div className="flex items-center gap-3">
                    <SearchUser
                        value={filters.assignedTo}
                        setValue={(value) => dispatchFilters({ type: 'assignedTo', value })}
                        defaultFields={["id", "email", "name", "firstname", "lastname"]}
                    />
                    <Button color="gray" size="sm" onClick={reload}>
                        {t("reload")}
                    </Button>
                </div>

            </div>
        </div>

        <div>
            <div className="grid grid-cols-2 md:grid-cols-3 p-2 gap-2">
                {/* not-completed */}
                <div className="bg-red-100 rounded-md p-2 flex space-x-4 items-center">
                    <span>{t("not-completed")}</span>
                    <span className="text-xl">
                        {numNotCompleted}/{tutors ? tutors.length : '-'}
                        <span className="text-sm text-gray-800 ml-3">{tutors && tutors.length ? format(".2%")(numNotCompleted / (tutors ? tutors.length : 1e-8)) : '- %'}</span>
                    </span>
                </div>

                {/* completed */}
                <div className="bg-yellow-100 rounded-md p-2 flex space-x-4 items-center">
                    <span>{t("completed")}</span>
                    <span className="text-xl">
                        {numCompleted}/{tutors ? tutors.length : '-'}
                        <span className="text-sm text-gray-800 ml-3">{tutors && tutors.length ? format(".2%")(numCompleted / (tutors ? tutors.length : 1e-8)) : '- %'}</span>
                    </span>
                </div>
                {/* readyForPairing */}
                <div className="bg-green-100 rounded-md p-2 flex space-x-4 items-center">
                    <span>{t("ready-for-pairing")}</span>
                    <span className="text-xl">
                        {numReadyForPairing}/{tutors ? tutors.length : '-'}
                        <span className="text-sm text-gray-800 ml-3">{tutors && tutors.length ? format(".2%")(numReadyForPairing / (tutors ? tutors.length : 1e-8)) : '- %'}</span>
                    </span>
                </div>
            </div>
            <ScrollArea viewportRef={scrollRef}>
                <Table>
                    {
                        !loading && tutors?.length === 0 && <TableCaption className="my-4">
                            {t("no-tutors")}
                        </TableCaption>
                    }
                    <TableHeader>
                        <TableRow className="hover:bg-gray-50 group">
                            <TableHead className={classNames("sticky pl-4 left-0 z-[1] group-hover:bg-gray-50 h-full bg-white whitespace-nowrap ", tutors?.length > 0  && isPastThreshold?.x ? "shadow-md":"shadow-none")}>
                                {t("tutor")}
                            </TableHead>
                            {
                                headers.map(({ title, className }, index) => {
                                    return <TableHead key={index} className={classNames( "z-0 whitespace-nowrap", className)}>
                                        {title}
                                    </TableHead>
                                })
                            }
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            tutors?.map((d, index) => {
                                return <TableRow key={`${d?.ni}-${index}`} className={classNames("group border-b-0 even:bg-gray-50 bg-white hover:bg-gray-100")}>
                                    <TableCell onClick={() => shouldOpenTutor(d?.ni)} className={classNames("sticky z-[1] left-0 cursor-pointer min-w-max font-medium bg-white group-even:bg-gray-50 py-0 group-hover:bg-gray-100 transition-all", isPastThreshold?.x ? "shadow-md":"shadow-none")}>
                                        <div className="flex items-center px-2 justify-between min-h-[55px]">
                                            <div className="mr-10">
                                                {d.name}
                                                {
                                                    !d.subscribed && <div className="text-xs text-red-500 text-nowrap">{t("auto-emails-disabled")}</div>
                                                }
                                                {d.retired_of_service_since && <div className="text-xs text-cyan-500">{t("tutor-is-retired", { date: locale.format('%d %B')(dateParse(d?.retired_of_service_since)) })}</div>}
                                            </div>
                                            <Pill.Empty className="text-xs text-nowrap text-center" color={d.retired_of_service_since ? "gray" : isCompleted(d) && d.antecedent_judiciaire_document_sent && d.antecedent_judiciaire_document_validated ? "green" : isCompleted(d) ? "yellow" : "red"}>
                                                <p className=" !min-w-[55px]">
                                                    {d.retired_of_service_since ? t("closed") : isCompleted(d) && d.antecedent_judiciaire_document_sent && d.antecedent_judiciaire_document_validated ? t("completed") : isCompleted(d) ? t("completed") : t("incomplete")}
                                                </p>
                                            </Pill.Empty>

                                        </div>
                                    </TableCell>
                                    {
                                        headers.map(({FormatComponent, itemClassName}, index) => {
                                            return <TableCell key={index} className={classNames("z-0", itemClassName)}>
                                                {
                                                    FormatComponent && <FormatComponent {...d} />
                                                }
                                            </TableCell>
                                        })
                                    }
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
                {tutors?.length > 0  && <ScrollBar orientation="horizontal" className="z-[2]"/>}

            </ScrollArea>
            {
                loading && tutors?.length > 0 && <div className="p-2">
                    <Skeleton className="w-full h-32" />
                </div>
            }

            <div className="px-4 pb-4">
                {paging && <Paginate setPage={setPage} {...paging} />}
            </div>


        </div>
    </>
}

function FilterGroup({ value, setValue }) {
    const { t } = useTranslation("common");
    const values = [
        { name: t("recruiting-groups.inscriptions"), value: "inscription" },
        { name: t("recruiting-groups.general"), value: "general" },
        { name: t("recruiting-groups.preferences"), value: "preferences" },
        { name: t("recruiting-groups.recruitment"), value: "recruitment" },
        { name: t("recruiting-groups.implications"), value: "implications" },
        { name: t("recruiting-groups.comments"), value: "comments" },
    ];
    const findValue = values.find(v => v.value === value);
    return <SelectMenu
        buttonClassName="!min-w-[10rem]"
        values={values}
        color="gray"
        value={findValue}
        setValue={(d) => setValue(d?.value)}
        indexKey="name"
    />
}

export function UserInscriptions() {
    const period = getCurrentPeriod();
    const [user,] = useUser();
    return <SearchContextProvider doNotStore defaultState={{ period, assignedTo: user }}>
        <TutorsContextProvider
            limit={20}
            fields={
                [
                    "assigned_user",
                    "contrat_engagement_document_received",
                    "cyber_security_policy_document_received",
                    "code_ethique_document_received",
                    "antecedent_judiciaire_document_received",
                    "schedule_availabilities",
                    "online_preference",
                    "on_site_preference",
                    "hybrid_preference",
                    "facebook_account",
                    "facebook_group",
                    "formation_completed",
                    "schools",
                    "subjects",
                    "email",
                    "phone",
                    "gender",
                    "city",
                    "street",
                    "zipcode",
                    "occupation",
                    "occupation_location",
                    "occupation_program",
                    "tutor_or_parent_is_immigrant",
                    "tshirt_size",
                    "comment_on_prefered_subjects",
                    "student_with_troubles",
                    "precision_on_student_with_troubles",
                    "student_in_frencization_class",
                    "precision_on_student_in_frencization",
                    "how_have_you_heard",
                    "how_have_you_heard_other",
                    "have_you_ever_been_tutor",
                    "active_periods",
                    "why_get_in",
                    "active_since",
                    "created_at",
                    "retired_of_service_since",
                    "reason_for_end_of_service",
                    "comments",
                    "form_comments",
                    "mails",
                    "subscribed",
                    "antecedent_judiciaire_document_conform",
                    "antecedent_judiciaire_document_sent",
                    "antecedent_judiciaire_document_validated",
                    "facebook_friend_status",
                    "inscription_notes",
                    "call_made"
                ]
            }
            //  Sort by data of inscription
            jsSortBy={(a, b) => {
                const insA = findActiveInscription(a?.active_periods || []);
                const insB = findActiveInscription(b?.active_periods || []);
                return new Date(insA?.created_at) > new Date(insB?.created_at) ? -1 : 1;
            }}
            augment={(d) => {
                const has_tutoring_type = d?.online_preference || d?.on_site_preference || d?.hybrid_preference;
                return ({ ...d, has_tutoring_type });
            }}
        >
            <UserInscriptionsWithContext hideCanAssign />
        </TutorsContextProvider>
    </SearchContextProvider>
}