
// api
import { searchPairings } from 'api/pairings';
import { searchStudents } from 'api/students';
import { searchTutors } from 'api/tutors';
import { searchCoachs } from 'api/coachs';

// Utils
import { dateParse, locale, parseWithTimeZone } from 'utils/locale';
import { cronToString } from 'utils/crons';
import { getCurrentPeriod } from 'utils/period';

const styles = {
    index : "font-medium max-w-xs truncate !py-1 ",
    base: "text-gray-600 max-w-xs  truncate ",
    subtil: "text-gray-400 max-w-xs  truncate font-medium",
    highlight: "text-gray-800 max-w-xs  truncate font-medium",
}

export const config = {
    students: {
      search: {
        school:true, 
        schoolFailedPeriods:true,
        schoolYear:true, 
        hasBeenMet:true,
        team:true, 
        period:true, 
        preset:true
      },
      fixedFilters: {},
      method: searchStudents,
      indexingKey: "ni",
      baseFields: ["ni", "name"],
      defaultFormatFields: [],
      columns: [
        {
          title: "name",
          fetchField: "name",
          field: "name",
          defaultActive: true,
          cannotRemove: true,
          itemClassName: styles.index+ " "
        },
        {
          title: "firstname",
          fetchField: "firstname",
          field: "firstname",
          itemClassName: styles.base,
          defaultActive: true,
          category: "general-infos",
        },
        {
          title: "lastname",
          fetchField: "lastname",
          itemClassName: styles.base,
          field: "lastname",
          defaultActive: true,
          category: "general-infos",
        },
        {
          title: "ni",
          fetchField: "ni",
          field: "ni",
          defaultActive: true,
          category: 'general-infos',
          itemClassName: styles.base 
        },
        {
          title: "birth-date",
          fetchField: "birth_date",
          field: "birth_date",
          itemClassName: styles.base,
          category: "general-infos",
        },
        {
          title: "country-born",
          itemClassName: styles.base,
          fetchField: "born_out_canada",
          field: "born_out_canada",
          format: (d=>d? 'Oui': 'Non'),
          category: "general-infos",
        },
        {
          title: "parent-country-born",
          fetchField: "parent_born_out_canada",
          itemClassName: styles.base,
          field: "parent_born_out_canada",
          format: (d=>d? 'Oui': 'Non'),
          category: "general-infos",
        },
        {
          title: "particular-needs",
          fetchField: "particular_needs",
          itemClassName: styles.base,
          field: "particular_needs",
          category: "general-infos",
        },
        {
          title: "motivation-level",
          itemClassName: styles.base,
          fetchField: "motivation",
          field: "motivation",
          category: "general-infos"
        },
        {
          title: "demand-for-tutoring.static-label",
          fetchField: "demand_for_tutoring",
          field: "demand_for_tutoring",
          defaultActive: true,
          category: 'Service',
          itemClassName: styles.base 
        },
        {
          title: "prioritary-for-tutoring.label",
          fetchField: "prioritary_for_tutoring",
          field: "prioritary_for_tutoring",
          defaultActive: true,
          category: 'Service',
          parseNull: true,
          format: (d=>d? 'Oui': 'Non'),
          itemClassName: styles.base 
        },
        {
          title: "student-has-been-met-and-discuss-for-tutoring.basic",
          fetchField: "tutoring_meeting_and_validation",
          field: "tutoring_meeting_and_validation",
          defaultActive: true,
          category: 'Service',
          parseNull: true,
          format: (d=>d? 'Oui': 'Non'),
          itemClassName: styles.base 
        },
        {
          title: "email",
          fetchField: "email",
          field: "email",
          defaultActive: true,
          itemClassName: styles.base,
          category: "Contact",
        },
        {
          title: "email2",
          fetchField: "email2",
          itemClassName: styles.base,
          field: "email2",
          defaultActive: true,
          category: "Contact",
        },
        {
          title: "phone",
          fetchField: "phone",
          itemClassName: styles.base,
          field: "phone",
          defaultActive: true,
          category: "Contact",
        },
        {
          title: "cellphone",
          fetchField: "cellphone",
          field: "cellphone",
          itemClassName: styles.base,
          defaultActive: true,
          category: "Contact",
        },
        {
          title: "social-network-name",
          itemClassName: styles.base,
          fetchField: "social_network_name",
          field: "social_network_name",
          category: "Contact",
        },
        {
          title: "prefered-communication",
          itemClassName: styles.base,
          fetchField: "prefered_communication",
          field: "prefered_communication",
          category: "Contact",
        },
        {
          title: "notebook-number",
          fetchField: "notebook_number",
          field: "notebook_number",
          itemClassName: styles.base,
          category: "Dossier"
        },
        {
          title: "folder-number",
          fetchField: "folder_number",
          itemClassName: styles.base,
          field: "folder_number",
          category: "Dossier"
        },
        {
          title: "group-class",
          fetchField: "group_class",
          field: "group_class",
          itemClassName: styles.base,
          category: "Dossier"
        },

        {
          title: "shirt-size",
          itemClassName: styles.base,
          fetchField: "tshirt_size",
          field: "tshirt_size",
          category: "general-infos",
        },

        {
          title: "pair-aidants",
          itemClassName: styles.base,
          fetchField: "pair_aidants",
          field: "pair_aidants",
          category: "general-infos",
          format: (d=>d == null? "": d? "Oui": "Non"),
          placeholder: "",
        },

        {
          title: "groupe-mifi",
          itemClassName: styles.base,
          fetchField: "groupe_mifis",
          field: "groupe_mifis",
          category: "general-infos",
          format: (d=>d == null? "": d? "Oui": "Non"),
          placeholder: "",
        },
        {
          title: "school",
          fetchField: "school",
          field: "school",
          format: (d=>d?d.name: "-"),
          placeholder: "-",
          itemClassName: styles.base,
          category: "Studies"
        },
        {
          title: "school-year",
          fetchField: "school_year",
          field: (d=>d?(d.school_year && d.school_year.name ): "school_year"),
          placeholder: "-",
          itemClassName: styles.base,
          category: "Studies"
        },
        {
          title: "siblings",
          fetchField: "siblings",
          field: "siblings",
          format: (d=>d?d.map(e=>`${e.description}: ${e.target.name}`).join(', '): ""),
          placeholder: "-",
          itemClassName: styles.base,
          category: "general-infos"
        },
        {
          title: "failed-school-classes",
          fetchField: "failed_classes",
          field: "failed_classes",
          format: (d=>d?d.map(e=>`${e.name}: (${e.score || "-"}%${e.group? " [" +e.group + " " + e.period+ "]": ""}) ${e.description|| ""}`).join(', '): ""),
          placeholder: "-",
          itemClassName: styles.base,
          category: "Studies"
        },
        {
          title: "num-failed-school-classes",
          fetchField: "failed_classes",
          field: (d=>d? d.failed_classes && d.failed_classes.length: 'num_failed_classes'),
          placeholder: "0",
          itemClassName: styles.base,
          category: "Studies"
        },
        {
          title: "pairings",
          fetchField: "active_pairings",
          field: "active_pairings",
          format: (d=>d? d.map(e=>`${e.tutor&& e.tutor.name}: (${e.started_at}) ${cronToString(e.period)}`).join(', '): ""),
          placeholder: "-",
          itemClassName: styles.base,
          category: "Service"
        },
        {
          title: "teams",
          fetchField: "teams",
          field: "teams",
          format: (d=>d?d.map(e=>`${e.team && e.team.name} (${e.team && e.team.active_period})`).join(', '): ""),
          placeholder: "-",
          itemClassName: styles.base,
          category: "Sport"
        },
        {
          title: "active-teams",
          fetchField: "teams",
          field: "teams",
          format: (d=>d?d.filter(d=>d.team && d.team.active_period===getCurrentPeriod()).map(e=>e.team && e.team.name).join(', '): ""),
          placeholder: "-",
          itemClassName: styles.base,
          category: "Sport"
        },
        {
          title: "participate-to-hc",
          fetchField: "participate_to_hc",
          field: "participate_to_hc",
          format: (d=>d? "Oui": "Non"),
          itemClassName: styles.base,
          placeholder: "-",
          category: "Sport"
        },
        {
          title: "online-preference",
          fetchField: "online_preference",
          field: "online_preference",
          parseNull: true,
          itemClassName: styles.base,
          format: (d=>d? "Oui": "Non"),
          category: "preferences"
        },
        {
          title: "on-site-preference",
          fetchField: "on_site_preference",
          field: "on_site_preference",
          parseNull: true,
          itemClassName: styles.base,
          format: (d=>d? "Oui": "Non"),
          category: "preferences"
        },
        {
          title: "hybrid-preference",
          fetchField: "hybrid_preference",
          field: "hybrid_preference",
          parseNull: true,
          itemClassName: styles.base,
          format: (d=>d? "Oui": "Non"),
          category: "preferences"
        },
        {
          title: "availabilities",
          fetchField: "schedule_availabilities",
          field: "schedule_availabilities",
          itemClassName: styles.base,
          format: (d=>d?d.map(s=>`${s.from} au ${s.to}`).join(', '): ""),
          category: "preferences"
        },
        {
          title:  "prefered-school-subjects",
          titleMethod:  (d=>d.name),
          fetchField: "prefered_subjects",
          field: (d=>d?d: `prefered_subjects-${d.slug}`),
          itemClassName: styles.base,
          expand: true,
          formatIsTranslation: true,
          parseNull: true,
          format: (d=>d&&d.id? "subject-description."+d.description: "-"),
          category: "Studies"
        },
        {
          title: "francization",
          fetchField: "francization",
          field: "francization",
          category: "Studies",
          itemClassName: styles.base,
          format: (d=>d? "Oui": "Non"),
        },
        {
          title: "count-failed-by-documents",
          fetchField: "failed_by_documents",
          indexingKey: "code",
          category: "Studies",
          field: (d=>`count-${d.group} ${d.period}`),
          filtering: ((d, {period})=>(!period? true: d.period===(period && period.name)? true: false)),
          infoMessage: (({period})=>(!period? null: `Le compte des échecs par document est limité aux échecs de la période ${period.name}. `)),
          titleMethod:  (d=>`${d.group} (${d.period}): Nombre d'échecs`),
          itemClassName: styles.base,
          expand: true,
          sortedGrouped: 'failed-by-documents',
          parseNull: true,
          format: (e=>!e? "0": e.failed_classes? `${e.failed_classes.length}`: "0"),
        },
        {
          title: "failed-by-documents",
          fetchField: "failed_by_documents",
          indexingKey: "code",
          category: "Studies",
          field: (d=>`${d.group} ${d.period}`),
          filtering: ((d, {period})=>(!period? true: d.period===(period && period.name)? true: false)),
          infoMessage: (({period})=>(!period? null: `Les documents des échecs sont filtrés pour n'afficher que la période ${period.name}.`)),
          titleMethod:  (d=>`${d.group} (${d.period}): Échecs`),
          itemClassName: styles.base,
          expand: true,
          parseNull: true,
          sortedGrouped: 'failed-by-documents',
          format: (e=>!e? "-": (e.failed_classes && e.failed_classes.length>0)? e.failed_classes.map(k=>`${k.subject}: (${k.score}%)`).join(', '): "-"),
        },
        {
          title:  "documents",
          indexingKey: "code",
          titleMethod:  (d=>d.group.name),
          fetchField: "documents",
          infoMessage: (({period})=>(!period? null: `Les dates des documents sont filtrées pour n'afficher que les documents de la période ${period.name}. Retirer le filtre sur la période pour voir tous les documents.`)),
          field: (d=>d.group.code),
          itemClassName: styles.base,
          expand: true,
          parseNull: true,
          format: ((e, {period})=>e.results? 
                          e.results.filter(d=>period? (d.period===period.slug) :true).map(l=>(l.fully_signed? "(S) ": "(NS) ")+(l.handed_over_at? locale.format("%d %B %Y")(new Date(l.handed_over_at)): 'Aucune date')).join(', ')
                          : "-"),
          category: "Dossier"
        },
        {
          title:  "professional-comments.title",
          field: "professional_comments",
          fetchField: "professional_comments",
          itemClassName: styles.base,
          format: (d)=> {
            if (!d) return "-"
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = d;
            return tempDiv.textContent || tempDiv.innerText || '';
          },
          category: "Dossier"
        },
        {
          title: "started-using-service",
          fetchField: "started_using_service",
          field: "started_using_service",
          itemClassName: styles.base,
          category: "Service"
        },
        {
          title: "ended-using-service",
          fetchField: "ended_using_service",
          field: "ended_using_service",
          itemClassName: styles.base,
          category: "Service"
        },
        {
          title: "reason-for-end",
          fetchField: "reason_for_end",
          field: "reason_for_end",
          category: "Service",
          itemClassName: styles.base,
        },
        {
          title: "work-hours",
          itemClassName: styles.base,
          fetchField: "work_hours",
          field: "work_hours",
        },
        {
          title: "future-dreams",
          fetchField: "future_dreams",
          itemClassName: styles.base,
          field: "future_dreams",
        },
        {
          title: "parent-motivation",
          fetchField: "parent_motivation",
          itemClassName: styles.base,
          field: "parent_motivation",
        },
        
      ]
    },
    tutors: {
      search: {
        availableForPairing:true, 
        leftService:true, 
        period:true
      },
      fixedFilters: {},
      method: searchTutors,
      indexingKey: "ni",
      baseFields: ["ni", "name"],
      defaultFormatFields: [],
      columns: [
        {
          title: "form-idx",
          fetchField: "form_idx",
          field: "form_idx",
          format: (d=>d? d: " "),
          defaultActive: true,
          includeInForm: true,
          category: 'Contact',
          itemClassName: styles.index 
        },
        {
          title: "name",
          fetchField: "name",
          field: "name",
          defaultActive: true,
          cannotRemove: true,
          includeInForm: true,
          itemClassName: styles.base 
        },
        {
          title: "ni",
          fetchField: "ni",
          field: "ni",
          defaultActive: true,
          includeInForm: false,
          category: 'Contact',
          itemClassName: styles.base 
        },
        {
          title: "firstname",
          fetchField: "firstname",
          field: "firstname",
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "Contact",
        },
        {
          title: "lastname",
          fetchField: "lastname",
          field: "lastname",
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "Contact",
        },
        {
          title: "email",
          fetchField: "email",
          field: "email",
          itemClassName: styles.base,
          placeholder: "-",
          includeInForm: true,
          category: "Contact",
        },
        {
          title: "phone",
          fetchField: "phone",
          field: "phone",
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "Contact",
        },
        {
          title: "city",
          fetchField: "city",
          field: "city",
          itemClassName: styles.base,
          placeholder: "-",
          includeInForm: true,
          category: "Contact",
        },
        {
          title: "street",
          fetchField: "street",
          field: "street",
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "Contact",
        },
        {
          title: "zipcode",
          fetchField: "zipcode",
          field: "zipcode",
          itemClassName: styles.base,
          placeholder: "-",
          includeInForm: true,
          category: "Contact",
        },
        {
          title: "instagram-account",
          fetchField: "instagram_account",
          field: "instagram_account",
          itemClassName: styles.base,
          placeholder: "-",
          category: "Contact",
        },
        {
          title: "facebook-account",
          fetchField: "facebook_account",
          field: "facebook_account",
          itemClassName: styles.base,
          placeholder: "-",
          category: "Contact",
        },
        {
          title: "gender",
          fetchField: "gender",
          field: "gender",
          itemClassName: styles.base,
          placeholder: "-",
          includeInForm: true,
          category: "Contact",
        },
        {
          title: "occupation",
          fetchField: "occupation",
          field: "occupation",
          format: (d=>d?d.name: "-"),
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "general-infos"
        },
        {
          title: "occupation-location",
          fetchField: "occupation_location",
          field: "occupation_location",
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "general-infos",
        },
        {
          title: "occupation-program",
          fetchField: "occupation_program",
          field: "occupation_program",
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "general-infos",
        },
        {
          title: "tutor-or-parent-is-immigrant",
          fetchField: "tutor_or_parent_is_immigrant",
          field: "tutor_or_parent_is_immigrant",
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "general-infos",
        },
        {
          title: "tshirt-size",
          fetchField: "tshirt_size",
          field: "tshirt_size",
          itemClassName: styles.base,
          placeholder: "-",
          includeInForm: true,
          category: "general-infos",
        },
        {
          title: "comments",
          itemClassName: styles.base,
          fetchField: "comments",
          field: "comments",
          placeholder: "-",
          includeInForm: true,
          category: "general-infos",
        },
        {
          title: "form-comments",
          fetchField: "form_comments",
          field: "form_comments",
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "general-infos",
        },
        {
          title: "availabilities",
          fetchField: "schedule_availabilities",
          field: "schedule_availabilities",
          itemClassName: styles.base,
          format: (d=>d? d.map(s=>`${s.from} au ${s.to}`).join(', '): null),
          category: "preferences"
        },
        {
            title: "pairings",
            fetchField: "active_pairings",
            field: "active_pairings",
            format: (d=>d? d.map(e=>`${e?.student?.name}: (${e.started_at}) ${cronToString(e.period)}`).join(', '): ""),
            placeholder: "",
            itemClassName: styles.base,
            category: "Service"
        },
        {
          title:  "tutor-subjects",
          titleMethod:  (d=>d.name),
          fetchField: "cross_subjects",
          returnedField: "subjects",
          field: (d=>`subjects-${d.slug}`),
          itemClassName: styles.base,
          expand: true,
          formatIsTranslation: true,
          parseNull: true,
          format: (d=>d&& d.id? "subject-description."+d.description: "-"),
          includeInForm: true,
          category: "preferences"
        },
        {
          title:  "school-preferences",
          titleMethod:  (d=>d.short_name),
          fetchField: "cross_schools",
          returnedField: "schools",
          field: (d=>`schools-${d.slug}`),
          itemClassName: styles.base,
          expand: true,
          formatIsTranslation: true,
          parseNull: true,
          format: (d=>d.slug? "availability."+d.description: "-"),
          includeInForm: true,
          category: "preferences"
        },        
        {
          title: "contrat-engagement-document-received",
          fetchField: "contrat_engagement_document_received",
          field: "contrat_engagement_document_received",
          format: (d=>d?locale.format("%d %B %Y")(parseWithTimeZone(d)): "Non"),
          placeholder: "Non",
          itemClassName: styles.base,
          category: "documents"
        },
        {
          title: "membership-document-received",
          fetchField: "membership_document_received",
          field: "membership_document_received",
          format: (d=>d?locale.format("%d %B %Y")(parseWithTimeZone(d)): "Non"),
          placeholder: "Non",
          itemClassName: styles.base,
          category: "documents"
        },
        {
          title: "cyber-security-policy-document-received",
          fetchField: "cyber_security_policy_document_received",
          field: "cyber_security_policy_document_received",
          format: (d=>d?locale.format("%d %B %Y")(parseWithTimeZone(d)): "Non"),
          placeholder: "Non",
          itemClassName: styles.base,
          category: "documents"
        },
        {
          title: "antecedent-judiciaire-document-received",
          fetchField: "antecedent_judiciaire_document_received",
          field: "antecedent_judiciaire_document_received",
          format: (d=>d?locale.format("%d %B %Y")(parseWithTimeZone(d)): "Non"),
          placeholder: "Non",
          itemClassName: styles.base,
          category: "documents"
        },
        {
          title: "code-ethique-confirmed",
          fetchField: "code_ethique_document_received",
          field: "code_ethique_document_received",
          format: (d=>d?locale.format("%d %B %Y")(parseWithTimeZone(d)): "Non"),
          placeholder: "Non",
          itemClassName: styles.base,
          category: "documents"
        },
        {
          title: "formation-completed",
          fetchField: "formation_completed",
          field: "formation_completed",
          format: (d=>d?locale.format("%d %B %Y")(dateParse(d)): "Non"),
          placeholder: "Non",
          itemClassName: styles.base,
          category: "documents"
        },
        {
          title: "ready-for-pairing",
          fetchField: "ready",
          field: "ready",
          format: (d=>d? "Oui": "Non"),
          placeholder: "-",
          category: "service",
        },
        {
          title: "online-preference",
          fetchField: "online_preference",
          field: "online_preference",
          parseNull: true,
          itemClassName: styles.base,
          format: (d=>d? "Oui": "Non"),
          includeInForm: true,
          category: "preferences"
        },
        {
          title: "on-site-preference",
          fetchField: "on_site_preference",
          field: "on_site_preference",
          parseNull: true,
          itemClassName: styles.base,
          format: (d=>d? "Oui": "Non"),
          includeInForm: true,
          category: "preferences"
        },
        {
          title: "hybrid-preference",
          fetchField: "hybrid_preference",
          field: "hybrid_preference",
          parseNull: true,
          itemClassName: styles.base,
          format: (d=>d? "Oui": "Non"),
          includeInForm: true,
          category: "preferences"
        },
        {
          title: "comment-on-prefered-subjects",
          fetchField: "comment_on_prefered_subjects",
          field: "comment_on_prefered_subjects",
          includeInForm: true,
          itemClassName: styles.base,
          placeholder: "-",
          category: "preferences",
        },  
        {
          title: "student-with-troubles",
          fetchField: "student_with_troubles",
          field: "student_with_troubles",
          includeInForm: true,
          itemClassName: styles.base,
          placeholder: "-",
          category: "preferences",
        },
        {
          title: "student-in-frencization-class",
          fetchField: "student_in_frencization_class",
          field: "student_in_frencization_class",
          includeInForm: true,
          itemClassName: styles.base,
          placeholder: "-",
          category: "preferences",
        },
        {
          title: "precision-on-student-with-troubles",
          fetchField: "precision_on_student_with_troubles",
          field: "precision_on_student_with_troubles",
          includeInForm: true,
          itemClassName: styles.base,
          placeholder: "-",
          category: "preferences",
        },
        {
          title: "precision-on-student-in-frencization",
          fetchField: "precision_on_student_in_frencization",
          field: "precision_on_student_in_frencization",
          includeInForm: true,
          placeholder: "-",
          itemClassName: styles.base,
          category: "preferences",
        },
        {
          title: "how-have-you-heard",
          fetchField: "how_have_you_heard",
          field: "how_have_you_heard",
          placeholder: "-",
          includeInForm: true,
          itemClassName: styles.base,
          category: "communication",
        },
        {
          title: "how-have-you-heard-other",
          fetchField: "how_have_you_heard_other",
          field: "how_have_you_heard_other",
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "communication",
        },
        {
          placeholder: "-",
          title: "why-get-in",
          fetchField: "why_get_in",
          field: "why_get_in",
          includeInForm: true,
          itemClassName: styles.base,
          category: "service",
        },
        {
          title: "active-since",
          fetchField: "active_since",
          field: "active_since",
          format: (d=>d?locale.format("%d %B %Y")(dateParse(d)): "-"),
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "service"
        },
        {
          title: "retired-of-service",
          fetchField: "retired_of_service_since",
          field: "retired_of_service_since",
          format: (d=>d?locale.format("%d %B %Y")(dateParse(d)): "-"),
          itemClassName: styles.base,
          placeholder: "-",
          category: "service",
        },
        {
          title: "reason-for-end-of-service",
          fetchField: "reason_for_end_of_service",
          field: "reason_for_end_of_service",
          itemClassName: styles.base,
          placeholder: "-",
          category: "service",
        },
        {
          title: "created-at",
          fetchField: "created_at",
          field: "created_at",
          format: (d=>d?locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(d)): "-"),
          placeholder: "-",
          itemClassName: styles.base,
          includeInForm: true,
          category: "service"
        },
        {
          title: "active-periods",
          fetchField: "active_periods",
          field: "active_periods",
          format: (d=>d?d.map(e=>e.name).sort((a,b)=>a>=b? 1:-1).join(', '): "-"),
          includeInForm: true,
          placeholder: "-",
          itemClassName: styles.base,
          category: "service",
        },
        {
          title: "already-been-tutor",
          fetchField: "active_periods",
          field: "active_periods",
          format: (d=>{
            if (!d){return "-"}
            const currentPeriod = getCurrentPeriod()
            if (d.filter(e=>e.slug !== currentPeriod).length === 0){
              return "non"
            }
            return "oui"
          }),
          includeInForm: true,
          placeholder: "-",
          itemClassName: styles.base,
          category: "service",
        },  
        {
          placeholder: "-",
          title: "have-you-ever-been-tutor",
          fetchField: "have_you_ever_been_tutor",
          field: "have_you_ever_been_tutor",
          includeInForm: true,
          itemClassName: styles.base,
          category: "service",
        },             
  
      ]
    },
    pairings: {
      search: {
        team:true, 
        school:true, 
        pairingActive:true, 
        period:true, 
        preset:true, 
        limitToUser: true,
        confirmedPairings: true

      },
      method: searchPairings,
      fixedFilters: {},
      indexingKey: "id",
      baseFields: ["id"],
      defaultFormatFields: [],
      columns: [
        {
          title: "student",
          fetchField: "student",
          field: "student",
          defaultActive: true,
          cannotRemove: true,
          format: (d=>d.name),
          itemClassName: styles.index 
        },
        {
          title: "tutor",
          fetchField: "tutor",
          field: "tutor",
          defaultActive: true,
          cannotRemove: true,
          format: (d=>d.name),
          itemClassName: styles.index
        },
        {
          title: "tutor-email",
          fetchField: "tutor",
          field: "tutor",
          format: (d=>d?d.email: "-"),
          itemClassName: styles.base,
          category: "contact"
        },
        {
          title: "school",
          fetchField: "student",
          field: "student",
          format: (d=>d.school),
          itemClassName: styles.base,
          category: "studies"
        },
        {
          title: "pairing-type",
          fetchField: "prefered_type",
          field: "prefered_type",
          format: (d=>d.name),
          itemClassName: styles.base,
          category: "service"
        },
        {
          title: "period",
          fetchField: "period",
          field: "period",
          format: cronToString,
          itemClassName: styles.base,
          category: "service"
        },
        {
          title: "pairing-subjects",
          fetchField: "subjects",
          field: "subjects",
          format: (d=>d ? d.map(d=>d.name).join(', '): "-"),
          itemClassName: styles.base,
          category: "studies"
        },
        {
          title: "teams",
          fetchField: "teams",
          field: "teams",
          format: (d=>d ? d.map(d=>d.name).join(', '): "-"),
          itemClassName: styles.base,
          category: "contact"
        },
        {
          title: "started-at",
          fetchField: "started_at",
          field: "started_at",
          itemClassName: styles.base,
          category: "service"
        },
        {
          title: "ended-at",
          fetchField: "ended_at",
          field: "ended_at",
          itemClassName: styles.base,
          category: "service"
        },
        {
          title: "reason-for-end",
          fetchField: "reason_for_end",
          field: "reason_for_end",
          itemClassName: styles.base,
          category: "service"
        },
        {
          title: "pairing-confirmed",
          fetchField: "confirmed",
          field: "confirmed",
          itemClassName: styles.base,
          category: "service"
        },
      ]
    },
    coachs: {
      search: {
        team:true, 
        school:true, 
        period:true, 
        preset:true, 
        limitToUser: true
      },
      method: searchCoachs,
      fixedFilters: {},
      baseFields: ["ni", "name"],
      indexingKey: "ni",
      defaultFormatFields: [],
      columns: [
        {
          title: "name",
          fetchField: "name",
          field: "name",
          defaultActive: true,
          cannotRemove: true,
          itemClassName: styles.index
        },
        {
          title: "email",
          fetchField: "email",
          field: "email",
          itemClassName: styles.base,
          category: "contact",
        },
        {
          title: "phone",
          fetchField: "phone",
          field: "phone",
          itemClassName: styles.base,
          category: "contact",
        },
        {
          title: "service-started-at",
          fetchField: "service_started_at",
          field: "service_started_at",
          itemClassName: styles.base,
          category: "service",
        },
        {
          title: "service-ended-at",
          fetchField: "service_ended_at",
          field: "service_ended_at",
          itemClassName: styles.base,
          category: "service",
        },
      ]
    }
}

const tutorFormColumns = [...config.tutors.columns.filter(d=>d.includeInForm)]
config.tutorForms = {...config.tutors, fixedFilters: {isForm: true, archived: true}, columns: tutorFormColumns}