import { useState, useEffect, RefObject } from 'react';

interface ScrollThresholdState {
    x: boolean;
    y: boolean;
}

export const useScrollThreshold = (
    ref: RefObject<HTMLElement>,
    thresholdX: number,
    thresholdY: number
): ScrollThresholdState => {
    const [isPastThreshold, setIsPastThreshold] = useState<ScrollThresholdState>({
        x: false,
        y: false,
    });
    
    useEffect(() => {
        const handleScroll = () => {
            if (!ref.current) return;

            const { scrollLeft, scrollTop } = ref.current;

            setIsPastThreshold({
                x: scrollLeft > thresholdX,
                y: scrollTop > thresholdY,
            });
        };

        const element = ref.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [ref, thresholdX, thresholdY]);

    return isPastThreshold;
};

