import { fetchCall } from 'api';
import { isBool, isString } from 'utils/var_check';

/*
  Search tutors
*/
export function searchTutors({search,
                            leftService,
                            serviceIsEnded,
                            availableForPairing,
                            returnPreferedSubjects,
                            returnPreferedSchools,
                            activeSinceFrom,
                            activeSinceTo,
                            period,
                            paired,
                            inService,
                            readyForPairing,
                            isForm,
                            archived,
                            assignedTo,
                            assigned,
                            fields,
                            page,
                            limit,
                            orderBy,
                            orderDirection}){
  const options = {
    method: "GET",
    endpoint: "/tutors",
    query: {search,
            availableForPairing: availableForPairing && (isBool(availableForPairing)? availableForPairing: availableForPairing.value),
            returnPreferedSubjects,
            returnPreferedSchools,
            paired: paired && (isBool(paired)? paired: paired.value),
            in_service: inService && (isBool(inService)? inService: inService.value),
            leftService: leftService && (isBool(leftService)? leftService: leftService.value),
            isForm: isForm && (isBool(isForm)? isForm: isForm.value),
            archived: archived && (isBool(archived)? archived: archived.value),
            serviceIsEnded: serviceIsEnded && (isBool(serviceIsEnded)? serviceIsEnded: serviceIsEnded.value),
            page,
            period: period && (isString(period)? period: period.slug),
            fields,
            readyForPairing,
            activeSinceFrom: activeSinceFrom && activeSinceFrom.toISOString(),
            activeSinceTo: activeSinceTo && activeSinceTo.toISOString(),
            assignedTo: assignedTo && (isString(assignedTo)? assignedTo: assignedTo.email),
            assigned: assigned && (isBool(assigned)? assigned: assigned.value),
            limit,
            orderBy,
            orderDirection}
  }
  return fetchCall(options)
}

/*
  Retrieve a tutor
*/
export const retrieveTutor = ({ni}) => {
  const options = {
    method: "GET",
    endpoint: `/tutors/${ni}`,
  }
  return fetchCall(options)
};

/*
  Retrieve school preferences
*/
export const retrieveSchoolPreferences = ({ni}) => {
  const options = {
    method: "GET",
    endpoint: `/tutors/${ni}/school-preferences`,
  }
  return fetchCall(options)
};
/*
  Update school preferences
*/
export const updateSchoolPreferences = ({ni, school, description}) => {
  const options = {
    method: "POST",
    endpoint: `/tutors/${ni}/school-preferences`,
    body: { school: school&&school.slug, description}
  }
  return fetchCall(options)
};


/*
  Retrieve subject preferences
*/
export const retrieveSubjectPreferences = ({ni}) => {
  const options = {
    method: "GET",
    endpoint: `/tutors/${ni}/subject-preferences`,
  }
  return fetchCall(options)
};
/*
  Update subject preferences
*/
export const updateSubjectPreferences = ({ni, subject, description}) => {
  const options = {
    method: "POST",
    endpoint: `/tutors/${ni}/subject-preferences`,
    body: { subject: subject&&subject.slug, description}
  }
  return fetchCall(options)
};


/*
  Search pairings
*/
export const searchPairings = ({ni, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET",
    endpoint: `/tutors/${ni}/pairings`,
    query: {page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
};


/*
  Close all active tutors
*/
export const closeAllActiveTutors = ({endedAt, reason}) => {
  const options = {
    method: "POST",
    endpoint: "/close-all-active-tutors",
    body: {endedAt: endedAt.toISOString().slice(0,10), reason}
  }
  return fetchCall(options)
};

/* Force sign on portal */
export const forceSignRequiredDocuments = ({ni}) => {
  const options = {
    method: "POST",
    endpoint: `/tutors/${ni}/force-sign-required-documents`,
  }
  return fetchCall(options)
};

/* Get portal user */
export const retrieveTutorPortalUser = ({ni}) => {
  const options = {
    method: "GET",
    endpoint: `/tutors/${ni}/portal-user`,
  }
  return fetchCall(options)
}; 
/*
  Update a tutor
*/
export const updateTutor = function({ni,
                            name,
                            firstname,
                            lastname,
                            email,
                            phone,
                            activeSince,
                            scheduleAvailabilities,
                            retiredOfServiceSince,
                            codeEthiqueDocumentReceived,
                            contratEngagementDocumentReceived,
                            membershipDocumentReceived,
                            cyberSecurityPolicyDocumentReceived,
                            antecedentJudiciaireDocumentReceived,
                            antecedentJudiciaireDocumentSent,
                            antecedentJudiciaireDocumentValidated,
                            antecedentJudiciaireDocumentConform,
                            precisionOnStudentInFrencization,
                            precisionOnStudentWithTroubles,
                            studentInFrencizationClass,
                            studentWithTroubles,
                            facebookAccount,
                            facebookGroup,
                            facebookFriendStatus,
                            commentOnPreferedSubjects,
                            comments,
                            callMade,
                            formationCompleted,
                            ready,
                            acceptTriade,
                            reasonForEndOfService,
                            onlinePreference,
                            onSitePreference,
                            hybridPreference}){
  const options = {
    method: "POST",
    endpoint: `/tutors/${ni}`,
    body: {name,
          firstname,
            lastname,
            email,
            phone,
            activeSince,
            scheduleAvailabilities,
            retiredOfServiceSince,
            comments,
            formationCompleted,
            ready,
            acceptTriade,
            precisionOnStudentInFrencization,
            precisionOnStudentWithTroubles,
            studentInFrencizationClass: studentInFrencizationClass && studentInFrencizationClass.value,
            studentWithTroubles: studentWithTroubles && studentWithTroubles.value,
            facebookAccount,
            facebookGroup,
            facebookFriendStatus,
            antecedentJudiciaireDocumentSent,
            antecedentJudiciaireDocumentValidated,
            antecedentJudiciaireDocumentConform,
            commentOnPreferedSubjects,
            reasonForEndOfService,
            callMade,
            codeEthiqueDocumentReceived,
            contratEngagementDocumentReceived,
            membershipDocumentReceived,
            cyberSecurityPolicyDocumentReceived,
            antecedentJudiciaireDocumentReceived,
            onlinePreference,
            onSitePreference,
            hybridPreference}
  }
  return fetchCall(options)
};


/*
  Delete a tutor
*/
export const deleteTutor = ({ni}) => {
  const options = {
    method: "DELETE",
    endpoint: `/tutors/${ni}`,
  }
  return fetchCall(options)
};

/*
  Create a tutor
*/
export function createTutor({firstname,
                              lastname,
                              email,
                              phone,
                              city,
                              street,
                              zipcode,
                              period,
                              occupation,
                              facebookAccount,
                              instagramAccount,
                              preferedFormat,
                              commentOnPreferedSubjects,
                              studentInFrencizationClass,
                              studentWithTroubles,
                              precisionOnStudentInFrencization,
                              precisionOnStudentWithTroubles,
                              haveYouEverBeenTutor,
                              howHaveYouHeard,
                              whyGetIn,
                              tutorOrParentIsImmigrant,
                              tshirtSize,
                              scheduleAvailabilities,
                              codeEthiqueDocumentReceived,
                              contratEngagementDocumentReceived,
                              membershipDocumentReceived,
                              cyberSecurityPolicyDocumentReceived,
                              antecedentJudiciaireDocumentReceived,
                              preferences,
                              schoolPreferences}){
  const options = {
    method: "POST",
    endpoint: `/tutors`,
    body: {firstname,
          lastname,
          email,
          phone,
          city,
          street,
          zipcode,
          period,
          occupation,
          facebookAccount,
          instagramAccount,
          preferedFormat,
          commentOnPreferedSubjects,
          studentInFrencizationClass,
          studentWithTroubles,
          precisionOnStudentInFrencization,
          precisionOnStudentWithTroubles,
          haveYouEverBeenTutor,
          howHaveYouHeard,
          whyGetIn,
          tutorOrParentIsImmigrant,
          tshirtSize,
          scheduleAvailabilities,
          codeEthiqueDocumentReceived,
          contratEngagementDocumentReceived,
          membershipDocumentReceived,
          cyberSecurityPolicyDocumentReceived,
          antecedentJudiciaireDocumentReceived,
          preferences: preferences && preferences.filter(d=>d.description && d.description!="none"),
          schoolPreferences: schoolPreferences && schoolPreferences.filter(d=>d.description && d.description!="none")
        }
  }
  return fetchCall(options)
}

/*
  Open tutor
*/
export const openTutor = ({ni, period}) => {
  const options = {
    method: "POST",
    endpoint: `/tutors/${ni}/open`,
    body: {period: period && (isString(period)? period: period.slug)}
  }
  return fetchCall(options)
};



/* Draft merge tutors*/

export function draftMergeTutors({ni1, ni2}){
  const options = {
    method: "POST",
    endpoint: `/tutors/${ni1}/draft-merge/${ni2}`
  }
  return fetchCall(options)
}

/* Apply merge tutors*/
export function applyMergeTutors({ni1, ni2}){
  const options = {
    method: "POST",
    endpoint: `/tutors/${ni1}/apply-merge/${ni2}`
  }
  return fetchCall(options)
}
/*
  Close tutor
*/
export const closeTutor = ({ni, endedAt, reason}) => {
  const options = {
    method: "POST",
    endpoint: `/tutors/${ni}/close`,
    body: {endedAt: endedAt.toISOString().slice(0,10), reason}
  }
  return fetchCall(options)
};

/*
 Tutoring overview
*/
export const getTutoringOverview = () => {
  const options = {
    method: "GET",
    endpoint: `/stats/global-tutoring-stats`,
  }
  return fetchCall(options)
}

/*
    Assignation to many tutors
*/
export const assignation = ({user, tutors}) => {
  const options = {
    method: "POST",
    endpoint: `/tutors/assignation`,
    body: {
        tutors: tutors.map(t=> typeof t === 'string'? t: t?.ni),
        email: typeof user === 'string'? user: user?.email
    }
  }
  return fetchCall(options)
}
