/*eslint-disable*/
import React, { useEffect, useRef } from 'react';
import * as Plot from '@observablehq/plot';

// Components
import { StatsCard, StatsCardTitle } from 'components/coordo-stats/stats-card';
import { CoordoStatsContextProvider } from 'contexts/coordo-stats';
import { Pill } from 'components/core/pill';
import { Button } from 'components/core/button';
// Utils
import classNames from 'classnames';

// Icons
import { FiChevronLeft as PrevIcon, FiChevronRight as NextIcon } from 'react-icons/fi';

// Hooks
import { useCoordoStats } from 'contexts/coordo-stats';
import { useElementSize } from 'hooks/useElementSize';
import { useDebounce } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';

export function MeetingsProgressionWithContext({ ...props }) {
    return <CoordoStatsContextProvider fields={"meetings"}>
        <MeetingsProgression {...props} />
    </CoordoStatsContextProvider>
}

function EmptyState() {
    const { t } = useTranslation();
    return <>
        <div className="flex justify-center items-center h-32">
            <p className="text-gray-400">{t("no-data-available")}</p>
        </div>
    </>
}

function MeetingsProgression({ navigationClassName, className, showStats = true}) {

    const { stats, loading, setDate } = useCoordoStats();
    const { t } = useTranslation();

    const {
        startDate,
        endDate,
        meetingsTarget,
        cumulativePerDay,
        prevPeriod,
        nextPeriod,
    } = stats?.meetings || {};
    const today = new Date().toLocaleDateString('en-CA');
    const start = startDate?.split('T')[0];
    const end = endDate?.split('T')[0];
    const inPeriod = today >= start && today <= end;
    const nextInPast = nextPeriod?.start ? nextPeriod?.start < today : false;
    const hideFuture = false;
    const inPast = start < today;
    const handleNext = () => {
        if (!nextPeriod) return;
        const next = new Date(nextPeriod?.start)
        next.setDate(next.getDate() + 1)
        setDate(next.toISOString());
    }
    const handlePrev = () => {
        if (!prevPeriod) return;
        const prev = new Date(prevPeriod?.start)
        prev.setDate(prev.getDate() + 1)
        setDate(prev.toISOString());
    }
    const handleToday = () => {
        if (inPeriod) return;
        setDate(new Date().toISOString());
    }
    return <>
        <StatsCard title={loading && !cumulativePerDay ? t("meetings-metrics.title") : undefined} loading={loading && !cumulativePerDay} className={classNames(className)}>
            <div className='@container'>
                
            <div className='flex justify-between flex-wrap gap-1'>
                <StatsCardTitle>{t("meetings-metrics.title")}</StatsCardTitle>
                <div className='flex flex-col justify-end gap-1 mt-1'>
                    {
                        start && end && <>
                            <div className={classNames("flex items-start gap-1", navigationClassName)}>
                                <div className={classNames(inPeriod ? "cursor-default" : "cursor-pointer", "flex flex-col")} onClick={handleToday}>
                                    <Pill.Interval short color={inPeriod ? undefined : "yellow"} fromDate={new Date(startDate)} toDate={new Date(endDate)} />
                                </div>
                                <div className='flex gap-1 items-start h-full mt-0.5'>
                                    <Button size="button" color="gray" className={classNames("p-1 rounded -mt-0.5", !prevPeriod && "opacity-50 pointer-events-none cursor-default")} onClick={handlePrev}><PrevIcon /></Button>
                                    <Button size="button" color="gray" className={classNames("p-1 rounded -mt-0.5", (!nextPeriod || (!nextInPast && hideFuture)) && "opacity-50 pointer-events-none cursor-default")} onClick={handleNext}><NextIcon /></Button>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            {
                !inPeriod && <div className='text-gray-500 @[500px]:text-base text-sm mt-0.5'>
                    {inPast && <p>{t("period-is-past")}</p>}
                    {!inPast && <p>{t("period-is-future")}</p>}
                </div>
            }
            </div>
            
            <div className='flex gap-4 flex-col mt-2'>
                {
                    !cumulativePerDay && !loading && <EmptyState />
                }
                {
                    cumulativePerDay && <>
                        <MeetingsProgressionGraph
                            cumulativePerDay={cumulativePerDay}
                            target={meetingsTarget}
                            start={start}
                            end={end}
                            className='w-full'
                        />
                        {
                            showStats && <MeetingsStats />
                        }
                    </>
                }
            </div>
        </StatsCard>
    </>
}


function formatToSingleDecimal(value) {
    return Number.isInteger(value) ? value : value.toFixed(1);
}

export function MeetingsStats() {
    const { t } = useTranslation();
    const { stats} = useCoordoStats();
    const {
        startDate,
        endDate,
        meetingsTarget,
        periodMeetingsTarget,
        requiredWeeklyProductivity,
        daysLeft,
        meetingsCount,
        schoolAverage,
    } = stats?.meetings || {};
    const today = new Date().toLocaleDateString('en-CA');
    const start = startDate?.split('T')[0];
    const end = endDate?.split('T')[0];
    const inPeriod = today >= start && today <= end;
    return <>
        <div className='flex gap-4 md:gap-4 flex-wrap justify-between my-2 tabular-nums @container'>
            <StatsItem title={t("meetings-metrics.meetings-done")}>
                <p className='@[320px]:text-4xl'>{meetingsCount}</p>
                <p className='@[320px]:text-2xl'>/ {meetingsTarget}</p>
            </StatsItem>
            {
                (schoolAverage || schoolAverage === 0) && <StatsItem title={t("meetings-metrics.school-average")}>
                    <p className='@[320px]:text-4xl'>{formatToSingleDecimal(schoolAverage)}</p>
                    <p className='@[320px]:text-2xl'>/ {periodMeetingsTarget}</p>
                </StatsItem>
            }
            <StatsItem title={t("meetings-metrics.time-left")}>
                {
                    inPeriod ? <>
                        <p className='@[320px]:text-4xl'>{daysLeft}</p>
                        <p className='@[320px]:text-2xl'>{daysLeft > 1 ? t("meetings-metrics.days") : t("meetings-metrics.day")}</p>
                    </>
                        :
                        <>
                            <p className='@[320px]:text-4xl'>0</p>
                            <p className='@[320px]:text-2xl'>{t("meetings-metrics.days")}</p>
                        </>
                }
            </StatsItem>
            <StatsItem title={t("meetings-metrics.meetings-needed")}>
                {
                    inPeriod ? <>
                        <p className='@[320px]:text-4xl'>{requiredWeeklyProductivity ? formatToSingleDecimal(requiredWeeklyProductivity) : "-"}</p>
                        <p className='@[320px]:text-2xl'>{t("meetings-metrics.per-week")}</p>
                    </>
                        :
                    <>
                        <p className='@[320px]:text-4xl'>0</p>
                        <p className='@[320px]:text-2xl'>{t("meetings-metrics.per-week")}</p>
                    </>
                }
            </StatsItem>
        </div>
    </>
}

function StatsItem({title, children}){

    return <>
        <div className="flex flex-col justify-end">
                <p className=''>{title}</p>
                <div className='flex items-end justify-center gap-2 h-full'>
                    {children}
                </div>
            </div>
    </>
}

function getTargetDayValue(target, dayIndex, startDate, endDate) {
    const dayDiff = (endDate - startDate) / (1000 * 60 * 60 * 24);
    const targetPerDay = target / dayDiff;
    return targetPerDay * dayIndex;
}

function MeetingsProgressionGraph({ cumulativePerDay, target, className, start, end }) {
    const plotRef = useRef(null);

    const { width } = useElementSize(plotRef);
    const deboucedWidth = useDebounce(width, 100);

    useEffect(() => {
        plotRef.current.innerHTML = '';
        if (!cumulativePerDay || Object.keys(cumulativePerDay).length === 0) {
            return;
        }
        const startDate = new Date(start)
        const endDate = new Date(end)
        const today = new Date().toLocaleDateString('en-CA'); 
        // Get today in yyyy-mm-dd format
        
        const inPeriod = today >= start && today <= end;
        const inFuture = today < start;
        const targetLine = [
            { date: startDate, target: 0 },
            { date: endDate, target: target }
        ];

        // let counter = 0;

        const formattedData = Object.entries(cumulativePerDay).map(([date, cumulative], index) => {
            // increment the counter 1/3 of the time
            // counter += Math.random() > 0.5 ? 3 : 0;
            // get the target value
            const targetValue = getTargetDayValue(target, index, startDate, endDate);
            return {
                date: new Date(date),
                cumulative: cumulative,
                target: targetValue,
            };
        });

        const maxCumulative = formattedData?.[formattedData?.length - 1].cumulative;

        const plot = Plot.plot({
            style: {
                overflow: "visible",
                width: "100%",
                fontSize: "13px",
            },
            height: (width || 100) / 2,
            width: width,
            marks: [
                // Difference
                !inFuture ? Plot.differenceY(
                    formattedData,
                    {
                        positiveFill: "#22c55e",
                        negativeFill: "#ef4444",
                        x1: "date",
                        y1: "target",
                        x2: "date",
                        y2: "cumulative",
                    },
                ) : undefined,
                // Cumulative meetings
                !inFuture ? Plot.line(formattedData, {
                    x: "date", y: "cumulative",
                    stroke: "black",
                    strokeWidth: 2,
                    marker: true,
                }) : undefined,
                // Target line
                Plot.line(targetLine, {
                    x: "date",
                    y: "target",
                    strokeDasharray: "10 4",
                    dy: -1,
                    dx: -1,
                }),
                // Today line
                inPeriod ? Plot.ruleX([today], {
                    strokeDasharray: "10 4",
                    opacity: 1
                }) : undefined,
                // Tip
                !inFuture ? Plot.tip(
                    formattedData,
                    Plot.pointerX({
                        x: "date",
                        y: "cumulative",
                        anchor: "bottom",

                    }),
                ) : undefined,
            ],
            x: {
                label: "Date",
                labelOffset: 35,
                domain: [startDate, endDate],
            },
            y: {
                label: "Rencontres",
                domain: [0, Math.max(maxCumulative, target)],
                grid: true,
                tickFormat: d => `${d}`,
            }
        });

        plotRef.current.append(plot);
        return () => plot.remove();

    }, [cumulativePerDay, deboucedWidth]);
    return <div className={className} ref={plotRef} />;
}
