import React, {createContext, useCallback, useContext } from "react";

// Hooks
import { useMemo} from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContext } from 'contexts/search';

// API
import { searchStudents } from 'api/students';

const StudentsContext = createContext({});

const StudentsContextProvider = ({ children }) => {
  const { filters } = useContext(SearchContext);
  const [students, { loading, error, paging, hasMore, setPage, setResult, execute:reload }] = useSearch(searchStudents, filters, {limit: 500, fields: ["ni", "name", "notebook_number", "ended_using_service"]});
  const isLoaded = useMemo(()=>!!students, [students]);

  const updateStudent = useCallback((student) => {
    setResult((students) => students.map((s) => s.ni === student.ni ? student : s));
  }, [setResult]);

  return (
    <StudentsContext.Provider value={{ students, setStudents: setResult, reload, loading, error, paging, setPage, hasMore, isLoaded, updateStudent }}>
      {children}
    </StudentsContext.Provider>
  );
};

export { StudentsContext, StudentsContextProvider };