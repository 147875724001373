import React, { useEffect } from 'react';

// Components
import { EmptyState } from 'components/core/empty';
import { Skeleton } from 'components/core/skeleton';
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"

// Hooks
import { useMemo } from 'react';

// Utils
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { locale } from 'utils/locale';
import { timeMonday, timeSunday,  timeDays, timeDay} from 'd3-time';
import { group, sort }  from 'd3-array';
import { ChangeWeek } from 'components/follow-up/aad';

const weekDayFormat = locale.format("%a.");
const dayFormat = locale.format("%d %b.");
const fullDayFormat = locale.format("%d %B %Y");

export function DailyEventsWeekView({date, 
                                    events, 
                                    compact,
                                    sortBy, 
                                    floorToMonday=true, 
                                    dayOffset=-1,
                                    numDays=5, 
                                    rightHeader, 
                                    setDate,
                                    setStartDate,
                                    setEndDate,
                                    EventComponent, 
                                    DailyComponent,
                                    emptyPlaceholder="empty-state.no-note-title"}){
  const {t} = useTranslation("common");
  const days = useMemo(()=>{
    if (floorToMonday){
      return timeDays(timeMonday.floor(date), timeDay.offset(timeMonday.floor(date), numDays) )
    }
    else {
      return timeDays(timeDay.offset(date, dayOffset), timeDay.offset(timeDay.offset(date, dayOffset),  numDays) )

    }
  }, [date]);
  useEffect(()=>{
    if (setStartDate) setStartDate(new Date(days?.[0]).toISOString());
    if (setEndDate) setEndDate(new Date(days?.[days.length-1]).toISOString());
  }, [days])
  const groupedEvents = useMemo(()=>{
    if (!events) return;
    const eventsGroupedByDays = group(events, d => fullDayFormat(d.date));
    const result = {};

    days.forEach((day)=>{
      result[day] = sort((eventsGroupedByDays.get(fullDayFormat(day)) || []), sortBy || (d=>d.date));
    })
    return result;
  }, [events, days])
  const formatToday = fullDayFormat(new Date());
  return  <div className=" ">

            <div className="flex flex-col relative ">
              {/* Header (date) */}
              
              <div className="sm:flex items-center sm:justify-between  flex-none">
                <div className="flex items-center flex-shrink-0 space-x-2">
                <ChangeWeek date={date} setDate={setDate} offset={1} leftApply={d=>timeDay.offset(timeSunday.floor(d), numDays-1)}
                  rightApply={d=>timeDay.offset(timeSunday.floor(d), numDays-1)} numWeeks={1}/>
                  
                </div>
                {rightHeader}
              </div>

              <ScrollArea className="pb-3">
                <div className='flex  rounded-lg space-x-3 mt-2'>
                
                {Object.keys(groupedEvents).map((date,i)=>
                        
                  <div className={classnames("flex-1 flex flex-col rounded-lg border overflow-hidden", fullDayFormat(new Date(date))==formatToday? "":"")} key={i}  style={{minWidth: 200}}>
                    <div className={classnames("flex-shrink-0 text-center z-0 border-b py-1 rounded-t-lg", fullDayFormat(new Date(date))==formatToday? " bg-blue-600 text-white":" bg-gray-200")}>{weekDayFormat(new Date(date))} <span className="font-semibold">{dayFormat(new Date(date))}</span></div>
                    <ScrollArea className={classnames("flex-1 w-full h-full p-2 bg-white space-y-2", compact && "max-h-96")}>
                        <div className={classnames("space-y-2")}>
                        {!groupedEvents && <Skeleton className="w-full h-64"/>}
                        {DailyComponent && <DailyComponent date={date}/>}
                        {groupedEvents[date].map((d,i)=><EventComponent key={i} {...d.content} date={d.content.date || d.date || date}/>)}
                        {groupedEvents[date].length===0 && <EmptyState description={t(emptyPlaceholder, {date: locale.format("%d %B")(new Date(date))})}/>}
                        </div>
                    </ScrollArea>
                  </div>
                )}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>

            </div>
          </div>
}
