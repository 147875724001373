import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { useSearchParams } from "react-router-dom";
import { useAPI } from 'hooks/useAPI';
import { useUser } from 'hooks/useUser';

// API
import { login } from 'api/auth';

// Components
import { Button } from 'components/core/button'
import { TextInput } from 'components/core/inputs'
import { Error, InfoSectionHeading } from 'components/core/typo'
import { Navigate } from 'react-router-dom';

export function LoginForm(){
  const { t } = useTranslation(["auth"]);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [, setUser] = useUser();
  const [searchParams,] = useSearchParams();
  const [result, {loading, error, execute}] = useAPI(login, {username, password},
                                                  {onResult: (({user})=>{user && setUser(user)}),
                                                  immediate: false});
  const onSubmit = useCallback((e)=>{
    if (e) e.preventDefault();
    execute();
  }, [execute]);

  if (result){
    if (result.should_setup_mfa){
      return <Navigate to={"/home"} state={result} />
    }
    if (result.token){
      const ref = searchParams.get("ref");
      return <Navigate to={`/mfa-sms-challenge?token=${result.token}&${ref?`ref=${ref}` :""}`} state={result}/>
    }
    if (searchParams.has("ref")){
      return <Navigate to={searchParams.get("ref")} state={result}/>
    }
    return <Navigate to="/" state={result}/>
  }
  
  return <form className="space-y-6" onSubmit={onSubmit}>
          <img src="/assets/logo-HD.png" className=" mx-auto h-auto max-h-12 lg:max-h-24 max-w-full"/>
          <div>
              <InfoSectionHeading mediumTitle={t('login.title')} />
            {/* <h1 className="text-lg text-gray-800 font-medium">{t('login.title')}</h1> */}
            {/* <p className="text-base text-gray-500">{t('login.description')}</p> */}
          </div>
          <TextInput 
            label={t("login.email.label")}
            autoComplete="email"
            type="email"
            required
            onChange={setUsername}/>
          <TextInput 
              label={t("login.password.label")} 
              type="password" 
              autoComplete="current-password"
              required
              hideEyeOnPassword={true}
              onChange={setPassword} />
          <Button color="inlineLink" size="link" href="/forgot-password">
            {t("login.forgot_password")} 
          </Button>

          <Button disabled={!username || !password} color="active" block size="xl" loading={loading}  type="submit">
            {t("login.submit")} 
          </Button>
          {error? <Error.Text {...error}/>: null}
         </form>
}
