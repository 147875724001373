/*eslint-disable no-unused-vars*/
import React, { useMemo, useContext, useState } from "react";

// Components
import { Table } from "components/core/table";
import { TutorsContextProvider, TutorsContext } from "contexts/tutors"
import { Skeleton } from "components/core/skeleton";
import { SummaryStatsList, SummaryStat } from "components/follow-up/meetings/summary";
import { SearchContextProvider, SearchContext } from 'contexts/search';
import { ListSearch } from "components/core/inputs";
import { Spinner } from "components/core/spinner";
import { Paginate } from "components/core/paginate";
import { FiCheck, FiTrash, FiX } from 'react-icons/fi';
import { SelectMenu } from "components/tools/select-menu";
import { Assignation } from "components/recruting/inscriptions/assignation";
import { Button } from "components/core/button";
import { Pill } from "components/core/pill";
// import { Tooltip } from 'components/core/tooltip'
import { TooltipContent, Tooltip, TooltipTrigger, TooltipProvider } from "@/components/ui/tooltip";

// Icons
import { MagnifyingGlassIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FiMail as MailIcon } from "react-icons/fi";

// Utils
import classNames from "classnames";
import { getCurrentPeriod, getFullCurrentPeriod } from "utils/period";

// Hooks
import { useTranslation } from "react-i18next";
// import { timeDay } from "d3";
import { dateParse, locale } from "utils/locale";

export const findActiveInscription = (activePeriods) => {
    const currPeriod = getCurrentPeriod();
    const inscriptions = activePeriods.filter(p => p?.slug === currPeriod);
    return inscriptions.length > 0 ? inscriptions[0] : null;
}

export function hasValue(value) {
    if (value == null) return false;
    if (value===false) return false;
    if (typeof value === 'string') return value.trim().length > 0;
    if (Array.isArray(value)) return value.length > 0;
    return true;
}

const requiredFields = [
    "contrat_engagement_document_received",
    "cyber_security_policy_document_received",
    "code_ethique_document_received",
    "antecedent_judiciaire_document_received",
    "antecedent_judiciaire_document_conform",
    "schedule_availabilities",
    "facebook_account",
    "formation_completed",
    "schools",
    "subjects"
];

const groupedFields = {
    'tutoring_type': ['online_preference', 'on_site_preference', 'hybrid_preference'],
};

export function getMissingInscriptionFields(tutor) {
    const missingFields = [];

    // Check grouped fields
    Object.keys(groupedFields).forEach(key => {
        if (groupedFields[key].every(field => !hasValue(tutor[field]))) {
            missingFields.push(key);
        }
    });

    // Check single fields
    requiredFields.forEach(field => {
        if (!hasValue(tutor[field])) {
            missingFields.push(field);
        }
    });

    return missingFields;
}

export function getCompletedFields(tutor) {
    const missingFields = [];

    // Check grouped fields
    Object.keys(groupedFields).forEach(key => {
        if (groupedFields[key].every(field => hasValue(tutor[field]))) {
            missingFields.push(key);
        }
    });

    // Check single fields
    requiredFields.forEach(field => {
        if (hasValue(tutor[field])) {
            missingFields.push(field);
        }
    });

    return missingFields;
}

export function isCompleted(tutor) {
    return getMissingInscriptionFields(tutor).length === 0;
}

export function InscriptionsWithContext() {
    const { t } = useTranslation("common");
    const { loading, paging, setPage, tutors, reload } = useContext(TutorsContext)
    const { filters, dispatchFilters } = useContext(SearchContext);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const isSelected = (tutor) => {
        if (typeof tutor === 'string') {
            return selected.map(s => s.ni).includes(tutor);
        }
        return selected.map(s => s.ni).includes(tutor.ni);
    };
    const hasAssignation = (tutor) => tutor.assigned_user !== null;
    const handleSelect = (tutor) => {
        setSelected((selected) => {
            if (isSelected(tutor)) {
                return selected.filter((s) => s.ni !== tutor.ni);
            }
            return [...selected, tutor];
        });
    };

    const headers = useMemo(() => {
        const items = [
            {
                field: (d) => d,
                headerId: "selected",
                className: "w-10",
                title: "",
                FormatComponent: (d) => <div className=''>
                    {isSelected(d) && <CheckIcon className={classNames('w-5 h-5 text-orange-500')} />}
                </div>,
                itemClassName: "!pr-0",
            },
            {
                field: (d) => d,
                title: t("name"),
                className: "",
                active: false,
                itemClassName: "py-3 !pl-0",
                FormatComponent: (d) => <div>
                    <Button color="hiddenLink" href={`/tutors/${d?.ni}`} onClick={(e)=>e?.stopPropagation()}>{d?.name}</Button>
                </div>
            },
            {
                field: (d) => d,
                title: t("completed"),
                className: "",
                active: false,
                itemClassName: "!pl-0 justify-center",
                FormatComponent: (d) => <InscriptionStatus tutor={d} />
            },
            {
                field: (d) => d,
                title: t("assigned-to"),
                className: "",
                active: false,
                itemClassName: "!pl-0",
                FormatComponent: (d) => <div>
                    <span className={""}>{d?.assigned_user?.name}</span>
                </div>
            },
            {
                field: (d) => d,
                title: t("source"),
                className: "",
                active: false,
                itemClassName: "!pl-0",
                FormatComponent: (d) => <div>
                    <InscriptionSource activePeriods={d?.active_periods || []} />
                </div>
            },
            {
                field: (d) => d,
                title: t("inscription-date"),
                className: "",
                active: false,
                itemClassName: "!pl-0 ",
                FormatComponent: (d) => {
                    const inscription = findActiveInscription(d?.active_periods || []);
                    const formattedDate = inscription && new Date(inscription?.created_at).toLocaleDateString('en-CA');
                    return <div>
                        <span className={"tabular-nums"}>{formattedDate}</span>
                    </div>
                }
                
            },
        ]
        return items;
    }, [tutors, selected]);
    return <>
        {/* <SectionHeading title={t("tutors")} >
            <InscriptionsStats />
        </SectionHeading> */}
        <div className="flex items-center justify-between p-3 border-b gap-2">
            <div className="flex items-center gap-2">
                <Button color="gray" size="sm" onClick={() => setSelected(tutors)}>
                    {t("search-tutors.select-all")}
                </Button>
                <Button color="delete" disabled={!selected || selected.length === 0} size="toolbarIcon" onClick={() => setSelected([])}>
                    <FiTrash />
                </Button>
            </div>
            
            <div className="flex gap-2 items-center">
                <div className="">
                    <Button color="gray" disabled={!selected || selected.length === 0} size="sm" onClick={() => setOpen(true)}>
                        {t("assignation.title")} ({selected.length})
                    </Button>
                    <Assignation 
                        values={selected} 
                        setValues={setSelected} 
                        open={open} 
                        setOpen={setOpen} 
                        onAssign={()=>reload?.()}
                    />
                </div>
                <FilterIsAssigned value={filters.assigned} setValue={(value) => dispatchFilters({ type: 'assigned', value })} />
            </div>
        </div>

        <div className="border-b relative">
            {
                loading ?
                    <Spinner className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" /> :
                    <MagnifyingGlassIcon
                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
            }
            <ListSearch color="none"
                noIcons
                loading={loading}
                search={true}
                className="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder={t("search-by-name")}
                onChange={(value) => dispatchFilters({ type: 'search', value })}
            />
        </div>
        <div className="@container mb-10">
            <Table
                onRowClick={handleSelect}
                rowClassName={(d)=>classNames("hover:bg-orange-50 hover:border-orange-100 group", isSelected(d) && "!bg-orange-100 border-orange-200 hover:border-orange-200", hasAssignation(d) && !isSelected(d) && "bg-gray-50 text-gray-600/50")}
                data={loading ? [] : tutors}
                headers={headers}
                indexingKey={(d) => d?.ni}
            />
            {
                loading && <div className="p-2">
                    <Skeleton className="w-full h-32" />
                </div>
            }
            {
                !loading && tutors?.length === 0 && <div className="p-2 flex justify-center text-gray-500">
                    {t("no-tutors")}
                </div>
            }
            <div className="px-4">
                {paging && <Paginate setPage={setPage} {...paging} />}
            </div>
        </div>
    </>;

}

function FilterIsAssigned({ value, setValue}) {
    const { t } = useTranslation("common");
    const values = [
        { name: t("assigned"), value: true }, 
        { name: t("not-assigned"), value: false },
        { name: t("all"), value: null },
    ];
    const findDefault = values.find(v => v.value === value);
    return <SelectMenu
        buttonClassName="!min-w-[10rem]"
        values={values}
        color="whiteOutline"
        value={value || findDefault}
        setValue={setValue}
        indexKey="name"
    />
}

function InscriptionSource({ activePeriods }) {
    const { t } = useTranslation("common");
    const inscription = findActiveInscription(activePeriods);
    const source = inscription?.source;
    // reinscription-portal
    // inscription-form
    // reinscription-form
    // system-open
    return <>
        {source && t(`inscription-sources.${source}`)}
    </>
}

export function InscriptionsStats() {
    return <>
        <SummaryStatsList>
            <SummaryStat title="Inscriptions">32</SummaryStat>
            <SummaryStat title="7 derniers jours">9</SummaryStat>
            <SummaryStat title="Non assignés">3</SummaryStat>
            <SummaryStat title="Complétés">29</SummaryStat>
        </SummaryStatsList>
    </>
}

// Pour la catégorie NON, c'est quand il manque un document du "COMPLÉTÉ"
// Pour la catégorie COMPLÉTÉ, ce qui doit être coché c'est : engagement, cyber, éthique, ant. reçus, ant. conformes, dispos, écoles, matières, format, formation
// Pour la catégorie PRÊT, ce qui doit être coché c'est : COMPLETÉ + ant envoyés, ant. validés


export function InscriptionStatus({ tutor }) {
    const { t } = useTranslation("common");
    const missingFields = getMissingInscriptionFields(tutor || {});
    const completedFields = getCompletedFields(tutor || {});
    const missing = (
        <div>
            <div className="font-medium p-0.5 pb-1">{t("missing-info")}</div>
            <div className="p-0.5 py-0">
                {missingFields.map((field, index) => (
                    <div key={index} className="flex items-center justify-between gap-3">
                        <span>{t(`tutor-inscription-fields.${field}`)}</span>
                        <FiX className="text-red-500" />
                    </div>
                ))}
            </div>
            <div className="font-medium p-0.5 pt-3 pb-1">{t("completed-info")}</div>
            <div className="p-0.5 py-0">
                {completedFields.map((field, index) => (
                    <div key={index} className="flex items-center justify-between gap-3">
                        <span>{t(`tutor-inscription-fields.${field}`)}</span>
                        <FiCheck className="text-green-500" />
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <TooltipProvider>
        <Tooltip delayDuration={300}>
            <TooltipTrigger>
                <div className="relative w-fit">
                    <BooleanPill value={missingFields.length === 0} />
                </div>
            </TooltipTrigger>
            <TooltipContent>
                {missing}
            </TooltipContent>
        </Tooltip>
        </TooltipProvider>
    );
}

export function BooleanPill({ value,showDate,...props }) {
    const { t } = useTranslation("common");
    return <Pill.Empty color={value ? value==="waiting"?"yellow": "green" : "red"} {...props}>
        {
            value ? 
            value==="no"?
            <div className="flex items-center gap-0.5 min-w-11"> 
                <XMarkIcon className="w-4 h-4 shrink-0" />{t("no")}
            </div>:
            value==="waiting"?
            <div className="flex items-center gap-0.5 min-w-11 whitespace-nowrap">
                <CheckIcon className="w-4 h-4 shrink-0  " />{t("waiting")}
            </div> :
            showDate?
            <div className="flex items-center gap-0.5 min-w-11 whitespace-nowrap">
                <CheckIcon className="w-4 h-4 shrink-0" />{locale.format("%d %b")(dateParse(value))}
            </div> 
            :
            <div className="flex items-center gap-0.5 min-w-11">
                <CheckIcon className="w-4 h-4 shrink-0" />{t("yes")}
            </div> 
            : 
            <div className="flex items-center gap-0.5 min-w-11"> 
                <XMarkIcon className="w-4 h-4 shrink-0" />{t("no")}
            </div>
        }
    </Pill.Empty>
}

export function Mails({ mails }) {
    const { t } = useTranslation("common");
    const { start, end } = getFullCurrentPeriod();
    const filteredMails = mails.filter((mail) => {
        const date = mail?.sent_at && new Date(mail?.sent_at) || new Date();
        return date >= start && date <= end;
    });
    const groupedMails = filteredMails.reduce((acc, mail) => {
        const date = new Date(mail.sent_at).toISOString().split("T")[0];
        acc[date] = acc[date] || [];
        acc[date].push(mail);
        return acc;
    }, {});
    const days = Object.keys(groupedMails).reverse();

    return (
        <TooltipProvider>
        <Tooltip delayDuration={300}>
            <TooltipTrigger>
                <Pill.Empty>
                    <MailIcon className="size-5 text-gray-500 shrink-0" />
                    <span className="ml-1">{mails.length}</span>
                </Pill.Empty>
            </TooltipTrigger>
            <TooltipContent>
                <div className="overflow-auto">
                    <span className="text-lg pb-3 font-medium">{t("emails")}</span>
                    {days.map((day) => (
                        <div key={day} className="pb-3">
                            <div className="flex items-center justify-between gap-3">
                                <span className="font-medium">{day}</span>
                                <span className="ml-auto text-gray-500">{t("num-day", { count: 1 })}</span>
                            </div>
                            {groupedMails[day].map((mail, index) => (
                                <div key={index} className="flex flex-col ml-1">
                                    <div className="text-gray-500 ml-2">{mail.mail_type.name}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </TooltipContent>
        </Tooltip>
        </TooltipProvider>
    );
}

export function Inscriptions() {
    const period = getCurrentPeriod();
    return <SearchContextProvider doNotStore defaultState={{ assigned: false, orderBy: "assigned_user", orderDirection:"desc", period}}>
        <TutorsContextProvider 
            fields={[
                "assigned_user", 
                "active_periods", 
                "active_since",
                "contrat_engagement_document_received",
                "cyber_security_policy_document_received",
                "code_ethique_document_received",
                "antecedent_judiciaire_document_received",
                "schedule_availabilities",
                "online_preference",
                "on_site_preference",
                "hybrid_preference",
                "facebook_account",
                "formation_completed",
                "schools",
                "subjects",
            ]}>
            <InscriptionsWithContext />
        </TutorsContextProvider>
    </SearchContextProvider>
}
