/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';

// API
import { getMeetingStatistics, searchGroupedNotes} from 'api/notes';

// Hooks
import { useMemo, useEffect, useState } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useAPI } from 'hooks/useAPI';
import { useQuery } from 'hooks/useQuery';
import { useWeek } from 'hooks/dates/useWeek'
import { useTranslation } from 'react-i18next';
// import {cronToString} from 'utils/crons';
import { computeNextOccurence, useNextOccurrence } from 'hooks/dates/useCronToDates';

// Utils
import classnames from 'classnames';
import { locale} from 'utils/locale';
import { timeWeek, timeMonday, timeTuesday, timeWednesday, timeThursday, timeFriday, timeSaturday, timeSunday, timeDay } from 'd3-time';
const fullDayFormatWithWeekDay = locale.format("%A %d %B %Y");

// API
import { createNote } from 'api/notes';

// Components
import { Spinner } from 'components/core/spinner';
import { Button } from 'components/core/button';
import { SearchNoteCode } from 'components/tools/search_note_code';
// import { SelectNoteGroup } from 'components/tools/select_note_group';
import { SelectAddNoteDay } from 'components/tools/select_add_note_day';
import { SlideOver } from 'components/core/slide_over';
import {  FiPlus, FiMousePointer, FiEdit2 } from 'react-icons/fi';
import { Table } from 'components/core/table';
import { Dropdown } from 'components/core/dropdown'
import { Tooltip } from 'components/core/tooltip'
import { NoteExtendedViewQueryBased } from 'components/notes/card';
import { NoteCellWeeklyView } from 'components/notes/list';
import { Skeleton } from 'components/core/skeleton';
import { Error } from 'components/core/typo'
import { Link } from 'react-router-dom';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { ChangeWeek } from 'components/follow-up/aad';
import { SelectDocumentGroup } from 'components/tools/select_document_group';
import { SelectPeriods } from 'components/tools/select_period';
import classNames from 'classnames';
import { HiOutlineExclamation } from 'react-icons/hi';
import { Stats } from 'components/core/stats';
import {format } from "d3-format"
import { getFullCurrentPeriod } from 'utils/period';
import { SearchFollowUpContext } from 'contexts/search_follow_up';

function MeetingStatistics({period, documentGroup, update}){
  const {filters} = useContext(SearchFollowUpContext);
  const { t } = useTranslation("common");
  const params = useMemo(()=>({period, documentGroup, team:filters.team, preset: filters.preset}), [period, documentGroup, filters.team, filters.preset, update  ])
  const [results, {error}] = useAPI(getMeetingStatistics, params);

  if (error) return <Error.Text {...error}/>
  if (!results) return <Skeleton className="w-full h-20" />;
  return <Stats.Container className="mb-4  xs:grid-cols-2 lg:grid-cols-8 !mx-0 !max-w-full">
            <Stats.Element name={t("meeting-stats.num-students")} value={results.num_students}/>
            <Stats.Element name={t("meeting-stats.mifi-count")} value={results.mifi_count}
            extra={!results.num_students ? "-%": format(".2%")((results.mifi_count || 0)/results.num_students)}/>
            {(results.other_notes||[]).map(d=>
              <Stats.Element style={{borderColor: d.color}} 
              className={"border-4"} 
              key={d.code} 
              name={`${d.initials || d.name}`} 
              description={results.document_group? '\n '+results.document_group.name: 'pour tous les documents'}
              value={d.count || "0"} 
              extra={!results.num_students ? "-%": format(".2%")((d.count || 0)/results.num_students)}/>)}
          </Stats.Container>
}

function EditCell({student, fromDate, notes, setOpen, setQuery, date, openDay, selectedCode, otherCreateParams, onResult}){
  const { t } = useTranslation("common");
  const nextOccurence = useNextOccurrence((student.teams 
    && student.teams.length>0 
    && student.teams[0].team.practice_periods 
    && student.teams[0].team.practice_periods.length>0 
    && student.teams[0].team.practice_periods[0]), date)


  // TODO ^ Fix for dropdown if multiple practice. And use the selected team for practice session.

  const openedAt = useMemo(()=>{
    switch (openDay){
      case 'Lundi':
        return timeMonday.ceil(date)
      case 'Mardi':
        return timeTuesday.ceil(date)
      case 'Mercredi':
        return timeWednesday.ceil(date)
      case 'Jeudi':
        return timeThursday.ceil(date)
      case 'Vendredi':
        return timeFriday.ceil(date)
      case 'Samedi':
        return timeSaturday.ceil(date)
      case 'Dimanche':
        return timeSunday.ceil(date)
      case 'Jour de la pratique':
        return nextOccurence
      default:
        return date
    }
  }, [openDay,  student, date]);

  const params = {student: student &&student.ni, code: selectedCode && selectedCode.code, openedAt: openedAt, closedAt: null, ...otherCreateParams}
  const [, {loading, error, execute, setResult}] = useAPI(createNote, params, {immediate:false, onResult});
  useEffect(()=>{
    setResult();
  }, [fromDate]);

  if (!student.ni){
    return null; 
  }
  return <div className="flex justify-left items-center mx-auto px-2">
              <div>
              {(student.teams||[]).length>1?
                    <Dropdown
                          onlyIcon={loading ? <Spinner/>: <FiPlus/>} 
                          disabled={loading ||!selectedCode}
                          menuItemsClassName="whitespace-nowrap"
                          >
                      {(student.teams||[]).map(d=>
                        {
                          var noteDate = "";
                                switch (openDay){
                                  case 'Lundi':
                                    noteDate = timeMonday.ceil(date)
                                    break
                                  case 'Mardi':
                                    noteDate =  timeTuesday.ceil(date);
                                    break
                                  case 'Mercredi':
                                    noteDate =  timeWednesday.ceil(date);
                                    break
                                  case 'Jeudi':
                                    noteDate =  timeThursday.ceil(date);
                                    break
                                  case 'Vendredi':
                                    noteDate =  timeFriday.ceil(date);
                                    break
                                  case 'Samedi':
                                    noteDate =  timeSaturday.ceil(date);
                                    break
                                  case 'Dimanche':
                                    noteDate =  timeSunday.ceil(date);
                                    break
                                  case 'Jour de la pratique':
                                    if (!d.team.practice_periods || d.team.practice_periods.length===0){
                                      noteDate = null
                                      break
                                    }
                                    noteDate =  computeNextOccurence(d.team.practice_periods[0], date);
                                    break
                                  default:
                                    noteDate =  date
                                    break
                                }
                            return <Dropdown.Item 
                              onClick={(()=>execute({ openAt: noteDate, closedAt: noteDate}))}  
                              key={d.team.slug} 
                              name={d.team.name}
                              disabled={!d.team || !noteDate || !otherCreateParams || !otherCreateParams.period || !otherCreateParams.documentGroup}
                              description={noteDate?fullDayFormatWithWeekDay(noteDate): `Aucune pratique`}/>
                          })}
                    </Dropdown>
                :
                <Tooltip content={t("add-note-on", {date: fullDayFormatWithWeekDay(openedAt), openDay:openDay, code: selectedCode && selectedCode.name})}>
                  <span><Button color={"white"} 
                    loading={loading} 
                    size="toolbarIcon" 
                    // className={"ring-2 ring-green-200"}
                    // style={{"--tw-ring-color": note? note.code.color: "null"}}
                    onClick={execute}
                    disabled={!openedAt || !selectedCode || !otherCreateParams || !otherCreateParams.period || !otherCreateParams.documentGroup} >
              {<FiPlus/>}
            </Button></span>
            </Tooltip>
          }</div>
     
          {notes && notes.map(d=>{
            
            // Convert d.code into initials
            const initials = d.code.initials || d.code.name.split(" ").map(d=>d[0]).join("");
              return <div key={d.id} className="px-3" onClick={()=>{setOpen(true); setQuery({noteId: d.id})}} >
                      <Tooltip 
                        position="center"
                        color="light"
                        delay={0}
                        className="fixed translate-x-6"
                        content={<NoteCellWeeklyView {...d} noBorder student={student}/>}>
                        
                        <div className="uppercase py-1 px-2 text-sm text-white whitespace-nowrap rounded-lg " style={{background:(d && d.code && d.code.color)|| 'bg-blue-500'}}>
                          <span className='whitespace-nowrap'>{initials}</span>
                        </div>
                      </Tooltip>
                    </div>
           })}
          {error? <Tooltip content={<Error.Text {...error}/>} color="transparent">
                <span className='relative bg-red-500 text-white h-7 w-7 rounded-md'><HiOutlineExclamation className='h-full w-full'/> </span>
                </Tooltip>: null}
        </div>
}

export function Cell({student, setQuery, setOpen, allNotes, notes, count, fraction, mifi}){
  const hasMifi = mifi && mifi.length>0;
  const allNotesContainsMifi = allNotes && allNotes.filter(d=>d.code.code==="ea-mifi-1ere-gen").length>0;

  if (!allNotes){
    return <div className="flex items-center justify-center space-x-3  text-lg font-bold "><span>{count}</span><span className='font-normal text-cyan-600'>{format(".2%")(fraction)}</span></div>
  }
  return <div className="flex items-center justify-around space-x-3">
           {notes && notes.map(note=>{

          const initials = note.code.initials || note.code.name.split(" ").map(d=>d[0]).join("");
              return <div key={note.id} className="relative px-3" onClick={()=>{setOpen(true); setQuery({noteId: note.id})}} >
                      <Tooltip 
                        position="center"
                        color="light"
                        delay={0}
                        className="fixed translate-x-6"
                        content={<NoteCellWeeklyView {...note} noBorder student={student}/>}>
                        <div className="uppercase py-1 px-2 text-sm text-white  rounded-lg " style={{background:(note && note.code && note.code.color)|| 'bg-blue-500'}}>
                          <span className='whitespace-nowrap'>{initials}</span>
                        </div>
                      </Tooltip>
                    </div>
           })}
           {(!notes || notes.length===0)
            && <div className={classnames("w-full h-8 flex flex-col mx-auto" , hasMifi ? "bg-purple-200":"bg-gray-200")}>
              {allNotes.map(note=><div key={note.id} className='flex-1 w-full opacity-40' style={{background:(note && note.code && note.code.color)|| 'white'}} />)}
              {hasMifi && !allNotesContainsMifi? <div className='flex-1 w-full opacity-40 bg-purple-200'/>: null}
              </div>}
        </div>
}

export function StudentCell({student}){
  if (!student.ni){
    return <div className="my-2 whitespace-nowrap">
            <p className={classnames("font-bold whitespace-nowrap ")}>{student.name}</p>
          </div>
  }
  return <div className="my-2 whitespace-nowrap">
          
            <p className={classnames("font-medium whitespace-nowrap")}><Link target='_blank' to={`/students/${student.ni}`} className='hover:underline'>{student.name}</Link></p>
          
          </div>
}

export function TeamsCell({student}){
  return <div className="">
          <p className={classnames("text-gray-500 min-w-[200px]")}>{(student.teams|| []).map(d=>d.team.name).join(', ')}</p>
          </div>
}

export function MifiCell({mifi, setQuery, setOpen}){
  return <div className="flex items-center jsutify-center">
          {mifi?
            mifi.map(d=><Button key={d} color="purple" size="smTight" onClick={()=>{setOpen(true); setQuery({noteId: d})}} >
            {`MIFI`}
          </Button>):
          null
          }
          </div>
}


export function NoPriorityCell({student, num_priority_notes}){
  const { t } = useTranslation("common");
  if (!student.ni){
    return null;
  }
  if (!num_priority_notes || num_priority_notes.length===0){
    return <div className="text-red-500 text-left px-2 text-sm">{t("no-priority-assigned")}</div>
  }
  return null;
}

function EndedCell({student}){
  if (student && student.ended_using_service){
    return <div className="text-pink-500 text-center px-1 bg-pink-100 rounded-md py-0.5 text-sm whitespace-nowrap">{student.ended_using_service}</div>
  }
  return null;
}

export function SummaryCell(){
  return <div className="">
          </div>
}

function EditMode({ value, setValue}){
  return <div className="flex items-center space-x-2 bg-gray-100 rounded-lg shadow inset-2">
          <Button color={value? "gray": "contrastWhiteGray"} size="toolbarIcon" onClick={()=>setValue(false)}><FiMousePointer/></Button>
          <Button color={!value? "gray": "contrastWhiteGray"} size="toolbarIcon" onClick={()=>setValue(true)}><FiEdit2/></Button>
         </div>
}

// Rencontres...!
export function NotesGrid({filters, numWeeks=4, stepDays=7}){
  const { t } = useTranslation("common");
  const {query, setQuery} = useQuery();
  const [open, setOpen] = useState(query.has("noteId"));

  useEffect(()=>{
    if (!open){setQuery()}
  }, [open])

  const [edit, setEdit] = useState(false);
  const [openDay, setOpenDay] = useState("Jour de la pratique");
  const [code, setCode] = useState();
  const [group, setGroup] = useState(); // Document group
  const [period, setPeriod] = useState(getFullCurrentPeriod());
  const [date, setDate] = useState(timeWeek.offset(timeMonday.floor(new Date()), 2));
  const {fromDate, toDate} = useWeek(date, {weeks: -numWeeks, floorInput:false});
  const searchParams = useMemo(()=>({...filters, 
                        fromDate, 
                        toDate, 
                        special: "rencontre",
                        // group,
                        documentGroup: group,
                        period,
                        code, 
                        stepDays, 
                        }),[fromDate, toDate, code, filters, group, period]);

  const searchParamsMemo = useMemoCompare(searchParams, (prev, next)=>{
    return (prev && prev.fromDate===next.fromDate
        && prev.toDate===next.toDate
        && prev.special===next.special
        && JSON.stringify(prev.preset)===JSON.stringify(next.preset)
        && JSON.stringify(prev.team)===JSON.stringify(next.team)
        && prev.code && next.code && prev.code.code ===next.code.code
        && prev.period && next.period && prev.period.slug ===next.period.slug
        && prev.documentGroup && next.documentGroup && prev.documentGroup.code ===next.documentGroup.code
        )
  })
  const [update, setUpdate] = useState(1); // Dummy variable to force update
  const [rawResults, {execute, loading} ] = useSearch(searchGroupedNotes, searchParamsMemo, {immediate: true, limit:500});
  const onUpdate = ()=>{
    setUpdate(d=>d+1);
    execute();
  }
  const results = useMemo(()=>{
    if (!rawResults) return;
    const flatResults =  rawResults.map(d=>({...d, allNotes: d.results.map(e=>e.notes).flat()}));

    if (code && code.code!=="rencontre-ea-entraineur"){
      return flatResults
    }
    const rencontreEACoordoCode = "rencontre-ea-entraineur";
   
    function addvector(a,b){
        return a.map((e,i) => e + b[i]);
    }
    
    const numStudents = rawResults.length;
    const numWeeks = rawResults[0].results.length;
    const values = rawResults.map(d=>d.results.map(e=>e.notes.filter(f=>f.code.code===rencontreEACoordoCode).length)).reduce(addvector, Array(numWeeks).fill(0));
    const totalNotesRencontreEA = {
      student: {name: t("total-rencontre-ea-coordo")},
      results: values.map((count)=>({count, fraction: count/(numStudents || 1)}))
    }
    return [...flatResults, totalNotesRencontreEA];

  }, [rawResults]);
  
  const headers = useMemo(()=>{
    if (!results || results.length===0) return [];

    // For each student, get the unique notes
    var h = [{
                title: t("students"),
                field: (d=>d?d: "student"),
                FormatComponent: StudentCell,
            },
            {
              title: t("teams"),
              field: (d=>d?d: "teams"),
              FormatComponent: TeamsCell,
            },
            {
              title: t("date-left-service"),
              field: (d=>d?d: "ended_using_service"),
              FormatComponent: EndedCell,
            },
            {
              title: t("mifi"),
              field: (d=>d?({...d, setQuery, setOpen}): "mifi"),
              FormatComponent: MifiCell,
            },
            {
              title: t("no-priority"),
              field: (d=>d?d: "no-priority"),
              FormatComponent: NoPriorityCell,
            }
        ];
    // Add an header for each date
    results[0].results.forEach((e,i)=>{
      const header = {
        title: `${locale.format("%d %B")(timeDay.offset(new Date(e.date), 1))}`,
        field: (d=>d? ({column: i, 
                      numColumns: results[0].results.length,
                      student: d.student, 
                      mifi: d.mifi,
                      allNotes: d.allNotes,
                      date: timeMonday.ceil(new Date(e.date)),
                      edit,
                      onResult: onUpdate,
                      setQuery,
                      openDay,
                      setOpen,
                      count: d.results[i].count,
                      fraction: d.results[i].fraction,
                      fromDate,
                      selectedCode: code,
                      otherCreateParams: {period, documentGroup: group},
                      notes: d.results[i].notes}): `date-${i}`),
        headerId: locale.format("%d %B")(new Date(e.date)),
        FormatComponent: (edit? EditCell: Cell),
        className: "text-center whitespace-nowrap px-4",
        itemClassName: classNames("pl-0 pr-0 relative border-x border-gray-50", edit && "odd:bg-gray-100")
      }
      h.push(header)
    })

    h.push({
              title: "",
              field: (d=>d?d: "total"),
              itemClassName: "w-full",
              FormatComponent: SummaryCell,
            })
    // Add the total
    return h;
  }, [ code, openDay, results, edit, fromDate])
// TODO!! STICKY
  return <div className="px-3 rounded-lg">
          <div className="z-10 flex flex-wrap items-center sm:space-y-0 py-4 justify-between -mt-3 ">
            <ChangeWeek date={date} setDate={setDate} offset={1} numWeeks={numWeeks} loading={loading}/>
            <div className="flex items-center space-x-3">
              {/*Insert center toolbar here*/}
              {/*<SelectNoteGroup value={group} setValue={setGroup} disabled={true} optionsClassName="max-w-xs w-screen" color="default"/>*/}
              <SearchNoteCode value={code} setValue={setCode} special={"rencontre"} disabled={false} size="smWider" targetWidth="full" color="default"/>
              <SelectDocumentGroup value={group} setValue={setGroup} targetWidth={"full"} color="default" />
              <SelectPeriods value={period} setValue={setPeriod} disabledClear={false} />
            </div>
            <div className="flex items-center space-x-3">
              {/*Insert left toolbar here*/}
              {edit &&<SelectAddNoteDay value={openDay} setValue={setOpenDay} targetWidth={"full"} color="default"/>}

              <EditMode value={edit} setValue={setEdit}/>
            </div>
          </div>
          <MeetingStatistics documentGroup={group} period={period} update={update}/>
         {!results?
            <Skeleton className="w-full h-80"/>:
            <div className="w-full overflow-auto">
              <Table repeatHeaders={true} headers={headers} data={results} indexingKey={(d=>`${d.student.ni}`)}/>
            </div>
          }
           <SlideOver open={open} setOpen={setOpen} size="xl3">
              <NoteExtendedViewQueryBased/>
            </SlideOver>
        </div>
}
