/* eslint-disable no-unused-vars */
import React from 'react';

// API
import { searchGroupedDocuments, createDocument } from 'api/documents';

// Hooks
import { useMemo, useEffect, useState } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useAPI } from 'hooks/useAPI';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useQuery } from 'hooks/useQuery';
import { useWeek } from 'hooks/dates/useWeek'
import { useTranslation } from 'react-i18next';
// import {cronToString} from 'utils/crons';
import { useNextOccurrence } from 'hooks/dates/useCronToDates';
import { format as numberFormat } from 'd3';

// Utils
import classnames from 'classnames';
import { locale } from 'utils/locale';
import { timeWeek, timeMonday, timeTuesday, timeWednesday, timeThursday, timeFriday, timeSaturday, timeSunday, timeDay } from 'd3-time';
import { getFullCurrentPeriod } from 'utils/period';
const fullDayFormatWithWeekDay = locale.format("%A %d %B %Y");

// API

// Components
import { Button } from 'components/core/button';
import { SelectAddNoteDay } from 'components/tools/select_add_note_day';
import { SelectPeriods } from 'components/tools/select_period';
import { SelectDocumentGroup } from 'components/tools/select_document_group';
import { SlideOver } from 'components/core/slide_over';
import { DocumentCard } from 'components/documents/card';
import { DocumentSlideOver } from 'components/documents/slideover';
import { FiPlus, FiCheck, FiMousePointer, FiEdit2, FiFlag } from 'react-icons/fi';
import { Table } from 'components/core/table';
import { Tooltip } from 'components/core/tooltip'
import { Skeleton } from 'components/core/skeleton';
import { Error } from 'components/core/typo'
import { Stats } from 'components/core/stats';

import { Popover } from 'components/tools/popover';
import { FiSettings } from 'react-icons/fi';
import { CheckBox } from 'components/tools/checkbox';
import { Link } from 'react-router-dom';
import { ChangeWeek } from 'components/follow-up/aad';
import { Paginate } from 'components/core/paginate';

function EditCell({ student, fromDate, documents, selectedGroup, period, setOpen, setQuery, date, openDay, onResult }) {
  const { t } = useTranslation("common");
  const nextOccurence = useNextOccurrence((student.teams
    && student.teams.length > 0
    && student.teams[0].team.practice_periods
    && student.teams[0].team.practice_periods.length > 0
    && student.teams[0].team.practice_periods[0]), date)

  const openedAt = useMemo(() => {
    switch (openDay) {
      case 'Lundi':
        return timeMonday.ceil(date)
      case 'Mardi':
        return timeTuesday.ceil(date)
      case 'Mercredi':
        return timeWednesday.ceil(date)
      case 'Jeudi':
        return timeThursday.ceil(date)
      case 'Vendredi':
        return timeFriday.ceil(date)
      case 'Samedi':
        return timeSaturday.ceil(date)
      case 'Dimanche':
        return timeSunday.ceil(date)
      case 'Jour de la pratique':
        return nextOccurence
      default:
        return date
    }
  }, [openDay, student, date]);

  const params = { fullySigned: true, student, handedOverAt: openedAt, group: selectedGroup, period }
  const [, { loading, error, execute, setResult }] = useAPI(createDocument, params, { immediate: false, onResult });
  useEffect(() => {
    setResult();
  }, [fromDate])

  return <div className="flex justify-center mx-auto">
    {((!documents || documents.length === 0)) &&
      <Tooltip content={t("add-document-on", { date: fullDayFormatWithWeekDay(openedAt), openDay, code: selectedGroup && selectedGroup.name })}>
        <div><Button color={"gray"}
          loading={loading}
          size="toolbarIcon"
          onClick={execute}
          disabled={!selectedGroup || !openedAt} >
          <FiPlus />
        </Button></div>
      </Tooltip>}
   
    {documents && documents.map(d => {
      const initial = d.group.initials || d.group.name.replace("#", "").replace("&", " ").split(' ').map(d => d[0]).join('').toUpperCase();
      return <div key={d.id} className="px-3" onClick={() => { setOpen(true); setQuery({ documentId: d.id }) }} >
        <Tooltip
          position="center"
          color="light"
          delay={0}
          className="fixed translate-x-6"
          content={<DocumentCard {...d} noBorder student={student} />}>
          <div className={classnames("py-1 px-2 text-white rounded-lg", d.fully_signed ? "bg-green-500" : "bg-yellow-500")}>{initial}</div>
        </Tooltip>
      </div>
    })}
    {error ? <Error.Text {...error} /> : null}
  </div>
}

export function Cell({ student, setQuery, setOpen, documents }) {
  return <div className="flex items-center justify-around space-x-3">
    {documents && documents.map(doc => {
      // Get the initial of the document
      const initial = doc.group.name.replace("#", "").replace("&", " ").split(' ').map(d => d[0]).join('').toUpperCase();
      return <div key={doc.id} className="px-3" onClick={() => { setOpen(true); setQuery({ documentId: doc.id }) }} >
        <Tooltip
          position="center-top"
          color="light"
          delay={0}
          className="fixed translate-x-6"
          content={<DocumentCard {...doc} noBorder student={student} />}>
          <div className={classnames("py-1 px-2 text-white rounded-lg", doc.fully_signed ? "bg-green-500" : "bg-yellow-500")}>{initial}</div>
        </Tooltip>
      </div>
    })}
    {(!documents || documents.length === 0)
      && <div className="w-full h-8 mx-auto bg-gray-100 "></div>}
  </div>
}

export function StudentCell({ student }) {
  return <div className="my-2 whitespace-nowrap">
    <Link to={`/students/${student.ni}`} target='_blank' className="hover:underline">
      <p className={classnames("font-medium whitespace-nowrap")}>{student.name}</p>
    </Link>
  </div>
}

export function TeamsCell({ student }) {
  return <div className="">
    <p className={classnames("text-gray-500 w-screen max-w-xs")}>{(student.teams || []).map(d => d.team.name).join(', ')}</p>
  </div>
}
export function SummaryCell({ student, all_documents, setQuery, setOpen }) {
  const { t } = useTranslation("common");

  const checkColor = useMemo(()=>{
    if (!all_documents){ return null}
    if (all_documents.length === 0){ return null}
    if (all_documents.filter(d=>d.fully_signed).length>0) return "bg-green-500";
    return "bg-yellow-500";
  }, [all_documents])

  return <div className="">
    {all_documents && all_documents.length > 0 &&
      <div className="flex items-center space-x-2 ">
        <div className={classnames("flex items-center justify-center w-6 h-6 text-lg text-white rounded-lg", checkColor)}>{checkColor==="bg-yellow-500"? <FiFlag/> :<FiCheck />}</div>
        <div className="flex items-center justify-start">
          {all_documents.map(d =>
            <div key={d.id} className="px-3" onClick={() => { setOpen(true); setQuery({ documentId: d.id }) }}>
              <Tooltip
                position="center"
                color="light"
                delay={0}
                className="fixed translate-x-6"
                content={<DocumentCard {...d} noBorder student={student} />}>
                <span className={classnames("relative", d.fully_signed? "text-green-600 bg-green-100 rounded-md px-2 py-1.5": 'text-yellow-600 bg-yellow-100 rounded-md px-2 py-1.5')}>
                  {d.handed_over_at? locale.format("%d %b %Y")(new Date(d.handed_over_at)): t("no-handed-over-at")}</span>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    }
  </div>
}

function EditMode({ value, setValue }) {
  return <div className="flex items-center space-x-2 bg-gray-100 rounded-lg shadow inset-2">
    <Button color={value ? "gray" : "contrastWhiteGray"} size="toolbarIcon" onClick={() => setValue(false)}><FiMousePointer /></Button>
    <Button color={!value ? "gray" : "contrastWhiteGray"} size="toolbarIcon" onClick={() => setValue(true)}><FiEdit2 /></Button>
  </div>
}


export function DocumentsGrid({ filters, numWeeks = 4, stepDays = 7 }) {
  const { t } = useTranslation("common");
  const { query, setQuery } = useQuery();
  const [open, setOpen] = useState(query.has("documentId"));

  useEffect(() => {
    if (!open) { setQuery() }
  }, [open])

  const [edit, setEdit] = useState(false);
  const [group, setGroup] = useState();
  const [showLeftServiceStudents, setShowLeftServiceStudents] = useState(false);
  const [period, setPeriod] = useState(getFullCurrentPeriod());
  const [openDay, setOpenDay] = useState("Jour de la pratique");
  const [date, setDate] = useState(timeWeek.offset(timeMonday.floor(new Date()), 2)); 
  const { fromDate, toDate } = useWeek(date, { weeks: -numWeeks, floorInput: false });
  const searchParams = useMemo(() => ({
    ...filters,
    fromDate,
    toDate,
    group,
    period,
    stepDays,
    showLeftServiceStudents,
  }), [fromDate, showLeftServiceStudents, toDate, group, period, edit, filters]);
  const _searchParams = useMemoCompare(searchParams, (prev, next) => {
    return (prev && next &&
      prev.group == next.group &&
      prev.stepDays == next.stepDays &&
      prev.team == next.team &&
      prev.showLeftServiceStudents == next.showLeftServiceStudents &&
      (prev.period && prev.period.slug) == (next.period && next.period.slug) &&
      prev.preset == next.preset &&
      prev.fromDate.toISOString() == next.fromDate.toISOString() &&
      prev.toDate.toISOString() == next.toDate.toISOString())
  })
  const [results, { setResult, paging, loading, execute }] = useSearch(searchGroupedDocuments, _searchParams, { immediate: true, limit: 500 });
  const summary = useMemo(() => {
    if (!results) return {};
    const numStudents = results.length;
    const numWithDocuments = results.reduce((acc, {all_documents})=>
        acc+((all_documents||[]).length>0? 1: 0), 
      0);
    const numSignedDocuments = results.reduce((acc, {all_documents})=>
      acc+((all_documents||[]).filter(d=>d.fully_signed).length>0? 1: 0), 
    0);
    const propotionWithDocuments = numWithDocuments/numStudents;
    const propotionSignedDocuments = numSignedDocuments/numStudents;
    return {
      numStudents, numSignedDocuments, numWithDocuments, propotionWithDocuments, propotionSignedDocuments};
  });

  const headers = useMemo(() => {
    if (!results || results.length === 0) return [];
    var h = [{
      title: t("students"),
      field: (d => d ? d : "student"),
      FormatComponent: StudentCell,
    },
    {
      title: t("teams"),
      field: (d => d ? d : "teams"),
      FormatComponent: TeamsCell,
    }
    ];

    // Add an header for each date
    results[0].results.forEach((e, i) => {
   
      const header = {
        title: `${locale.format("%d %B")(timeDay.offset(new Date(e.date), 1))}`,
        field: (d => d ? ({
          column: i,
          numColumns: results[0].results.length,
          student: d.student,
          date: timeMonday.ceil(new Date(e.date)),
          edit,
          setQuery,
          openDay,
          setOpen,
          fromDate,
          period,
          onResult: execute,
          selectedGroup: group,
          documents: d.results[i].documents
        }) : `date-${i}`),
        headerId: locale.format("%d %B")(new Date(e.date)),
        FormatComponent: (edit ? EditCell : Cell),
        className: "text-center whitespace-nowrap px-4 ",
        itemClassName: "pl-0 pr-0 relative"
      }
      h.push(header)
    })

    h.push({
      title: "",
      field: (d => d ? ({ ...d, setQuery, setOpen }) : "total"),
      itemClassName: "w-full relative",
      FormatComponent: SummaryCell,
    })
    // Add the total
    return h;
  }, [openDay, group, period, edit, results, fromDate])

  return <div className="px-3 rounded-lg">
    <div className="sticky overflow-visible z-10 flex flex-wrap items-center sm:space-y-0 py-4 justify-between -mt-3 bg-white">
      <ChangeWeek date={date} setDate={setDate} offset={1} numWeeks={numWeeks} loading={loading}/>

      <div className="flex items-center space-x-3 md:w-fit">
        {/*Insert center toolbar here*/}
        <SelectDocumentGroup value={group} setValue={setGroup} targetWidth={"full"} color="default" />
        <SelectPeriods value={period} setValue={setPeriod} />
      </div>
      <div className="flex items-center pt-2 space-x-3 sm:pt-0">
        <Popover color="default" size='fit' label={<FiSettings className='w-5 h-5 text-gray-600' />}>
          <CheckBox
            name={t("show-left-service-students.title")}
            description={showLeftServiceStudents ? t("show-left-service-students.positive") : t("show-left-service-students.negative")}
            value={showLeftServiceStudents}
            setValue={setShowLeftServiceStudents} />
        </Popover>
        {/*Insert left toolbar here*/}
        {edit && <SelectAddNoteDay value={openDay} setValue={setOpenDay} targetWidth={"full"} color="default" />}

        <EditMode value={edit} setValue={setEdit} />
      </div>
    </div>
    {!results ?
      <Skeleton className="w-full h-80" /> :
      <>
       {group && period && <Stats.Container className="mb-4  xs:grid-cols-2 lg:grid-cols-6 !mx-0 !max-w-screen">
            <Stats.Element name={t("document-stats.num-students")} value={summary.numStudents}/>
            <Stats.Element name={t("document-stats.num-with-documents")} value={summary.numWithDocuments}/>
            <Stats.Element name={t("document-stats.prop-with-documents")} unit="" value={numberFormat(".1%")(parseFloat(summary.propotionWithDocuments))}/>
            <Stats.Element name={t("document-stats.num-signed-documents")} value={summary.numSignedDocuments}/>
            <Stats.Element name={t("document-stats.prop-signed-documents")} unit="" value={numberFormat(".1%")(parseFloat(summary.propotionSignedDocuments))}/>
        </Stats.Container>}
        <div className="w-full h-full">
        {paging&& paging.total_pages!==1 &&  <Paginate {...paging}/>}
         
        <Table headers={headers} data={results} indexingKey={(d => `${d.student.ni}`)} />
        {paging&& <Paginate {...paging}/>}

      </div>
      </>
    }
    <SlideOver open={open} setOpen={setOpen} size="xl3">
      <DocumentSlideOver 
      onDelete={({id})=>{setOpen(false); setResult(results.map(el => 
        ({ ...el,  
          all_documents: (el.all_documents||[]).filter(el2 => el2.id !== id),
        results: el.results.map(el2 => ({ ...el2, documents: el2.documents.filter(el3 => el3.id !== id) })) })))}}
      onUpdate={d => {
        setResult(results.map(el => ({ ...el, all_documents: (el.all_documents||[]).filter(el2=>el2.id===d.id? d: el2) , results: el.results.map(el2 => ({ ...el2, documents: el2.documents.map(el3 => el3.id === d.id ? d : el3) })) })));
      }
      } />
    </SlideOver>
  </div>
}
