/* eslint-disable no-unused-vars */
import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';
import { useAPI } from 'hooks/useAPI';
import { FiShield } from 'react-icons/fi';

// API
import { answerSMSChallenge, resendChallenge } from 'api/auth';

// Components
import { Button } from 'components/core/button'
import { InputOTP, InputOTPGroup, InputOTPSlot } from 'components/ui/input-otp'
import { Error, RawTextWarning } from 'components/core/typo'

export function InlineSMSChallengeForm({ onCompleted, token }) {
    const { t } = useTranslation(["auth"]);
    const [answer, setAnswer] = useState();
    const [hasResendCode, setHasResendCode] = useState();
    const params = useMemo(() => ({ token, answer }), [answer, token]);
    const [result, { loading, error, execute }] = useAPI(answerSMSChallenge, params,
        {
            onResult: onCompleted,
            immediate: false
        });
    const [, resend] = useAPI(resendChallenge, { token }, { onResult: setHasResendCode, immediate: false })

    if (result) {
        return <div className="bg-green-100 p-3 rounded-md text-green-700">{t("mfa.phone-has-been-validated")}</div>
    }
    return <div className="space-y-6 border-2 shadow-md p-3 rounded-md">
        <div className="md:flex md:space-x-4">
            <div className='relative h-20 w-20 bg-orange-100 text-orange-700 p-3 rounded-full mx-auto'>
                <FiShield className="w-full h-full" />
            </div>
            <div>
                <h1 className="text-lg text-gray-800 font-medium">{t('sms-challenge.setup-mfa-title')}</h1>
                <p className="text-base text-gray-500">{t('sms-challenge.setup-mfa-description')}</p>
            </div>
        </div>
        <Button color="inlineLink" size="link" loading={resend.loading} onClick={resend.execute}>
            {t("sms-challenge.resend-code")}
        </Button>
        {hasResendCode ? <RawTextWarning>{t("sms-challenge.resend-code-success")}</RawTextWarning> : null}
        <InputOTP maxLength={6} value={answer} onChange={setAnswer}>
            <InputOTPGroup className='w-full'>
                <InputOTPSlot className='h-12 w-full text-lg' index={0} />
                <InputOTPSlot className='h-12 w-full text-lg' index={1} />
                <InputOTPSlot className='h-12 w-full text-lg' index={2} />
                <InputOTPSlot className='h-12 w-full text-lg' index={3} />
                <InputOTPSlot className='h-12 w-full text-lg' index={4} />
                <InputOTPSlot className='h-12 w-full text-lg' index={5} />
            </InputOTPGroup>
        </InputOTP>
        <Button disabled={!answer} color="active" block size="lg" loading={loading} onClick={execute}>
            {t("sms-challenge.submit")}
        </Button>
        {resend.error ? <Error.Text {...resend.error} /> : null}
        {error ? <Error.Text {...error} /> : null}
    </div>
}
