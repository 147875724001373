/* eslint-disable no-unused-vars */
import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useQuery } from 'hooks/useQuery';
import { useValidatePassword } from 'hooks/validation/useValidatePassword';
import { useUser } from 'hooks/useUser';

// API
import { signup } from 'api/auth';

// Components
import { Button } from 'components/core/button'
import { TextInput } from 'components/core/inputs'
import { Error } from 'components/core/typo'
import { Navigate } from 'react-router-dom';

export function SignupForm(){
  const { t } = useTranslation(["auth", "errors"]);
  const {query} = useQuery();
  const [email, setEmail] = useState(query.get("e"));
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [password, setPassword] = useState();
  const [, setUser] = useUser();

  const validatePassword = useValidatePassword();

  const [result, {loading, error, execute}] = useAPI(signup, {email, firstname, lastname, password, token:query.get('t')},
                                                  {onResult: setUser,
                                                  immediate: false});

  const onSubmit = useCallback((e)=>{
    if (e) e.preventDefault();
    execute();
  }, [execute]);

  if (result){
    return <Navigate to="/"/>
  }
  
  return <form className="space-y-6" onSubmit={onSubmit}>
          <img src="/assets/logo.jpeg" className=" mx-auto h-auto max-h-24 max-w-full"/>
          <h1 className="text-xl">{t('signup.title')}</h1>
          <p className="text-gray-500">{t('signup.description')}</p>
          <TextInput 
            label={t("signup.email.label")}
            autoComplete="email"
            type="email"
            required
            disabled={true}
            defaultValue={email}
            placeholder={email}
            onChange={setEmail}/>
          <TextInput 
              label={t("signup.password.label")} 
              type="password" 
              autoComplete="current-password"
              required
              errorOnBlur={v=>v? validatePassword(v): null}
              description={t("signup.password.help")}
              onChange={setPassword} />
          <hr/>
          <TextInput 
            label={t("signup.firstname.label")}
            autoComplete="firstname"
            type="firstname"
            required
            onChange={setFirstname}/>
          <TextInput 
            label={t("signup.lastname.label")}
            autoComplete="lastname"
            type="lastname"
            required
            onChange={setLastname}/>

          <Button disabled={!email || validatePassword(password) || !firstname || !lastname} color="active" block size="lg" loading={loading}  type="submit">
            {t("signup.submit")} 
          </Button>
          {error? <Error.Text {...error}/>: null}
         </form>
}
