import React from 'react';

// Components
import { FiWifi, FiRotateCcw, FiUsers, FiVideoOff, FiMeh, FiClock, FiMicOff, FiBriefcase } from 'react-icons/fi';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

// Hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import { dateParse, locale } from 'utils/locale';
import classnames from 'classnames';
import { Button } from 'components/core/button';


function SessionFormat({ format }) {
    if (!format || !format.code) return null;

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger>
                    <div>
                        {format.code === "en-personne" ? (
                            <FiUsers className="text-sm" />
                        ) : (
                            <FiWifi className="text-sm text-green-600" />
                        )}
                    </div>
                </TooltipTrigger>
                <TooltipContent>{format.name}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}


function MissingMaterial({ preset, notes }) {
    const note = useMemo(() => {
        if (!notes || !notes.length) return;
        const n = notes.filter(d => d.code.code === "manque-de-materiel-a-son-aide-aux-devoirs");
        if (n.length === 0) return;
        return n[0];
    }, [notes]);

    if (!note) return null;

    const content = (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger>
                    <div>
                        <FiBriefcase className="text-red-500" />
                    </div>
                </TooltipTrigger>
                <TooltipContent>{note?.code?.name}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );

    if (preset) {
        return (
            <Button href={`/suivi-terrain/${preset.id}/bdi?noteId=${note?.id}&date=${note?.opened_at}`}>
                {content}
            </Button>
        );
    } else {
        return content;
    }
}

function Retaken({ is_retaken, original_period }) {
    const { t } = useTranslation("common");

    if (!is_retaken) return null;

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger>
                    <div>
                        <FiRotateCcw className="text-red-500" />
                    </div>
                </TooltipTrigger>
                <TooltipContent>
                    {t("study-session-is-retaken", {
                        originalPeriod: locale.format("%d %B")(dateParse(original_period)),
                    })}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}

function CameraOff({ preset, notes }) {
    const note = useMemo(() => {
        if (!notes || !notes.length) return null;
        const n = notes.filter((d) => d.code.code === "camera-fermee");
        if (n.length === 0) return null;
        return n[0];
    }, [notes]);

    if (!note) return null;

    const content = (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger>
                    <div>
                        <FiVideoOff className="text-red-500" />
                    </div>
                </TooltipTrigger>
                <TooltipContent>{note?.code?.name}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );

    if (preset) {
        return (
            <Button href={`/suivi-terrain/${preset?.id}/bdi?noteId=${note?.id}&date=${note?.opened_at}`}>
                {content}
            </Button>
        );
    } else {
        return content;
    }
}

function LackConcentration({ preset, notes }) {
    const note = useMemo(() => {
        if (!notes || !notes.length) return null;
        const n = notes.filter((d) => d.code.code === "manque-de-concentration-a-son-aide-aux-devoirs");
        if (n.length === 0) return null;
        return n[0];
    }, [notes]);

    if (!note) return null;

    const content = (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger>
                    <div>
                        <FiMeh className="text-red-500" />
                    </div>
                </TooltipTrigger>
                <TooltipContent>{note?.code?.name}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );

    if (preset) {
        return (
            <Button href={`/suivi-terrain/${preset?.id}/bdi?noteId=${note?.id}&date=${note?.opened_at}`}>
                {content}
            </Button>
        );
    } else {
        return content;
    }
}

function LateToStudySession({ preset, notes }) {
    const note = useMemo(() => {
        if (!notes || !notes.length) return null;
        const n = notes.filter((d) => d.code.code === "retard-a-son-aide-aux-devoirs");
        if (n.length === 0) return null;
        return n[0];
    }, [notes]);

    if (!note) return null;

    const content = (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger>
                    <div>
                        <FiClock className="text-red-500" />
                    </div>
                </TooltipTrigger>
                <TooltipContent>{note?.code?.name}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );

    if (preset) {
        return (
            <Button href={`/suivi-terrain/${preset?.id}/bdi?noteId=${note?.id}&date=${note?.opened_at}`}>
                {content}
            </Button>
        );
    } else {
        return content;
    }
}

function MissingRecording({ preset, notes }) {
    const note = useMemo(() => {
        if (!notes || !notes.length) return null;
        const n = notes.filter(
            (d) => d.code.code === "absence-denregistrement-a-son-aide-aux-devoirs"
        );
        if (n.length === 0) return null;
        return n[0];
    }, [notes]);

    if (!note) return null;

    const content = (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger>
                    <div>
                        <FiMicOff className="text-red-500" />
                    </div>
                </TooltipTrigger>
                <TooltipContent>{note?.code?.name}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );

    if (preset) {
        return (
            <Button href={`/suivi-terrain/${preset?.id}/bdi?noteId=${note?.id}&date=${note?.opened_at}`}>
                {content}
            </Button>
        );
    } else {
        return content;
    }
}

export function StudySessionIconContent({notes, length_in_minutes, cancelled_for_external_reason, technical_problem,  id}){
  const chokeNote = useMemo(()=>{
     if (!notes || !notes.length) return;
     const n = notes.filter(d=>d.code.code.includes("rencontre-manquee-sans-avertissement"));
     if (n.length===0) return;
     return n[0];
    }, [notes])

  const cancelNote = useMemo(()=>{
     if (!notes || !notes.length) return;
     const n = notes.filter(d=>d.code.code.includes("rencontre-annulee"));
     if (n.length===0) return;
     return n[0];
  }, [notes])

  const chokeWithReasonNote = useMemo(()=>{
     if (!notes || !notes.length) return;
     const n = notes.filter(d=>d.code.code.includes("rencontre-manquee-pour"));
     if (n.length===0) return;
     return n[0];
  }, [notes])

  return <div className={classnames(
              chokeNote? "bg-red-100 text-red-600":
              cancelNote? "bg-yellow-100 text-yellow-700":
              cancelled_for_external_reason? "bg-blue-100 text-blue-700":
              technical_problem? "bg-blue-100 text-blue-700":
             chokeWithReasonNote? "bg-red-100 text-red-700":
              "bg-green-100 text-green-600",
              "my-1 py-1 px-2 mx-auto rounded text-center relative ")}>
            <Button href={`/study-sessions/${id}`}>{length_in_minutes}</Button>
           {/* {notes && notes.length>0 && 
              <div className="absolute px-1 py-0 text-xs font-medium flex items-center justify-center text-white rounded-md top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-red-600">
              {notes.length}</div>}
*/}
            {chokeNote && 
              <div className={"absolute px-1 py-0 text-xs font-medium flex items-center justify-center text-white rounded-md top-0 left-0 -translate-x-1/2 -translate-y-1/2 bg-red-600"}>
                {chokeNote.code.code==="rencontre-manquee-sans-avertissement-de-leleve-athlete" && "EA"}
                {chokeNote.code.code==="rencontre-manquee-sans-avertissement-du-tuteur" && "T"}
              </div>
            }
            {cancelNote && 
              <div className={"absolute px-1 py-0 text-xs font-medium flex items-center justify-center text-white rounded-md top-0 left-0 -translate-x-1/2 -translate-y-1/2 bg-yellow-500"}>
                {cancelNote.code.code==="rencontre-annulee-a-lavance-par-letudiant" && "EA"}
                {cancelNote.code.code==="rencontre-annulee-par-leleve-athlete-et-le-tuteur-benevole" && "EA-T"}
                {cancelNote.code.code==="rencontre-annulee-par-le-tuteur-benevole" && "T"}
              </div>
            }
            {chokeWithReasonNote && 
              <div className={classnames("absolute px-1 py-0 text-xs font-medium flex items-center justify-center text-white rounded-md top-0 left-0 -translate-x-1/2 -translate-y-1/2", 
                  chokeWithReasonNote.code.code==="rencontre-manquee-pour-probleme-technique" && "bg-blue-700",
                chokeWithReasonNote.code.code==="rencontre-manquee-pour-une-raison-externe" && "bg-blue-400")}>
                {chokeWithReasonNote.code.code==="rencontre-manquee-pour-probleme-technique" && "P"}
                {chokeWithReasonNote.code.code==="rencontre-manquee-pour-une-raison-externe" && "EXT"}
              </div>
            }
          </div>
}

export function StudySessionIcons({notes, session_format, preset, ...props}){

  return <div className='flex items-center space-x-1 relative z-10'>
        
            <SessionFormat format={session_format}/>
            <Retaken {...props}/>
            <CameraOff preset={preset} notes={notes}/>
            <LackConcentration preset={preset} notes={notes}/>
            <LateToStudySession preset={preset} notes={notes}/>
            <MissingRecording preset={preset} notes={notes}/>
            <MissingMaterial preset={preset} notes={notes}/>
        </div>
}

