import React, {createContext, useState } from "react";

// Hooks
import { useEffect } from 'react';
import { SlideOver } from "components/core/slide_over";
import { NoteExtendedViewQueryBased } from "components/notes/card";
import { useQuery } from "hooks/useQuery";

const NoteSliderOverContext = createContext({});

const NoteSliderOverContextProvider = ({ children }) => {

    const {query, setQuery} = useQuery();
    const [open, setOpen] = useState(query.has("noteId"));
    useEffect(()=>{
        if (query.has("noteId")){
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [query]);

    useEffect(()=>{
        if (!open){
            setQuery({});
        }
    }, [open]);

    const shouldOpenNote = (noteId) => {
        setQuery({noteId});
        setOpen(true);
    }
    return (
    <NoteSliderOverContext.Provider value={{ shouldOpenNote }}>
      {children}
      <SlideOver open={open} setOpen={setOpen} size="xl3">
        <NoteExtendedViewQueryBased/>
    </SlideOver>
    </NoteSliderOverContext.Provider>
  );
};

export { NoteSliderOverContext, NoteSliderOverContextProvider };
