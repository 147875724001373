import React, { useContext } from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { WeeklyView } from 'components/events/weekly_view';
import { CoordoStats } from 'components/coordo-stats';
import { ShouldSetMFACardWrapper } from 'components/alerts/should-set-mfa';
import { RawTextInfo } from 'components/core/typo';

// Contexts
import { HomeContext, HomeContextProvider } from 'contexts/home';

// Hooks
import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();
    const {filters} = useContext(HomeContext);
    const {coordo} = filters || {};
    return <DashboardLayout contentClassName="!bg-gray-100">
        <ShouldSetMFACardWrapper />
        <div className="grid grid-cols-1 gap-3 p-3 xl:grid-cols-2 sm:gap-6 sm:p-6 ">
            
            <div className="justify-center w-full col-span-full rounded-xl">
                <WeeklyView Context={HomeContext} />
            </div>
            
            <div className='w-full col-span-full'>
                <CoordoStats.StudentsMetricsGrid 
                    header={
                        coordo && <div className=''>
                            <RawTextInfo title={t("homepage-viewed-as-other", {name: coordo?.name})} className="w-fit h-fit bg-white" />
                        </div>
                    }
                />
            </div>

            <div className="grid xl:grid-cols-2 col-span-full gap-4">
                <CoordoStats.MeetingsProgression className="w-full h-full" />
                <CoordoStats.Communications className="w-full" />
            </div>

            <div className='w-full col-span-full xl:col-span-1'>
                <CoordoStats.StudySessions />
            </div>

        </div>
        

    </DashboardLayout>
}


export function HomeCoordo() {
    return <HomeContextProvider>
        <Home />
    </HomeContextProvider>
}