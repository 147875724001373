import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/utils"

const buttonVariants = cva(
    "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
    {
        variants: {
            variant: {
                destructive:
                    "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
                outline:
                    "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
                secondary:
                    "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
                ghost: 
                    "hover:bg-accent hover:text-accent-foreground",
                link: 
                    "text-primary underline-offset-4 hover:underline",
                contrastWhite: 
                    'shadow border-0 bg-white text-orange-600 hover:text-orange-800 hover:bg-gray-200 transition-all duration-100 outline-none ',
                contrastWhiteGray: 
                    'shadow border-0 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-200 transition-all duration-100 outline-none ',
                contrastWhiteGrayInactive: 
                    'opacity-40 shadow border-0 bg-gray-200 text-gray-600 hover:text-gray-800 hover:bg-gray-200 transition-all duration-100 outline-none ',
                cardNav: 
                    'font-medium border-0 text-orange-600 hover:text-orange-800 hover:bg-gray-100  transition-all duration-100 outline-none',
                warning: 
                    'shadow-sm border-0 bg-yellow-100 text-yellow-600 hover:text-yellow-800 hover:bg-yellow-200 transition-all duration-100 outline-none ',
                default: 
                    'shadow-none border-0 bg-gray-100 text-gray-600 hover:text-gray-800 hover:bg-gray-200 transition-all duration-100 outline-none ',
                active: 
                    'shadow-none border-0 text-white bg-orange-600 hover:text-white hover:bg-orange-500 outline-none ',
                info: 
                    'shadow-none border-0 text-blue-600 bg-blue-100 hover:text-blue-700 hover:bg-blue-50 outline-none ',
                gray: 
                    'shadow-none border-0 text-gray-600 bg-gray-100 hover:text-gray-700 hover:bg-gray-50 outline-none ',
                green: 
                    'shadow-none border-0 text-green-600 bg-green-100 hover:text-green-700 hover:bg-green-50 outline-none ',
                toolbarIcon: 
                    "text-orange-600 hover:text-orange-700 hover:bg-orange-100 rounded-md",
                delete: 
                    'shadow-none border-0 text-red-600 hover:text-red-700 hover:bg-red-50 outline-none ',
                deleteContrast: 
                    'shadow-none border-0 bg-red-500 text-white hover:bg-red-600 outline-none ',
                black: 
                    'flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900',
                warningCard: 
                    'shadow border-0 bg-white text-yellow-600 hover:text-yellow-100 hover:bg-yellow-700 transition-all duration-100 outline-none ',
                infoCard: 
                    'shadow border-0 bg-white text-vlue-600 hover:text-vlue-100 hover:bg-vlue-700 transition-all duration-100 outline-none ',
                paginate: 
                    'border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100',
                hiddenLink: 
                    'shadow-none border-0 hover:underline block w-fit',
                inlineLink: 
                    'text-orange-500 shadow-none border-0 hover:underline block w-fit',
                errorLink: 
                    'text-red-700 underline shadow-none border-0 hover:underline hover:opacity-70',
                warningLink: 
                    'text-yellow-700 underline shadow-none border-0 hover:underline hover:opacity-70',
                baseLink: 
                    'text-gray-500 shadow-none border-0 hover:text-orange-500 hover:underline',
                nav: 
                    "text-gray-500 font-medium",
                activeNav: 
                    "text-gray-900 hover:text-gray-700  border-b border-gray-500",
                sideNav: 
                    "text-gray-400 hover:text-gray-200 hover:bg-gray-700 ",
                activeSideNav: 
                    "text-white hover:text-orange-50  bg-orange-600 md:border-r-2 md:border-r-orange-600 2xl:border-0",
                subSideNav: 
                    "text-gray-500 rounded-md  bg-white hover:text-gray-800 hover:bg-gray-50",
                activeSubSideNav: 
                    "text-gray-900  rounded-md bg-gray-100",
                cell: 
                    "bg-white hover:bg-gray-200",
                activeCell: 
                    "bg-orange-500 hover:bg-orange-500",
                activeFlag: 
                    "bg-red-400 text-white hover:bg-red-400",
                flag: 
                    "bg-gray-50 text-gray-300 hover:bg-gray-100 hover:text-gray-600",
                purple: 
                    "bg-purple-100 text-purple-600 hover:bg-purple-200",
                none: 
                    "",
            },
            size: {
                default: "h-9 px-4 py-2",
                sm: "h-8 rounded-md px-3 text-xs",
                lg: "h-10 rounded-md px-8",
                icon: "h-9 w-9",
                none: "",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    }
)

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
    asChild?: boolean
    href?: string
    target?: React.HTMLAttributeAnchorTarget
}
import { Link } from "react-router-dom"
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, href, target, ...props }, ref) => {
        if (href) {
            return (
                <Link
                    to={href}
                    className={cn(buttonVariants({ variant, size, className }))}
                    target={target}
                >
                    {props.children}
                </Link>
            );
        }
        const Comp = asChild ? Slot : "button"
        return (
            <Comp
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                {...props}
            />
        )
    }
)
Button.displayName = "Button"

export { Button, buttonVariants }
