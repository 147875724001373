/* eslint no-unused-vars: "off" */

import React from "react";

// Components
import { Skeleton } from "components/core/skeleton";

// Utils
import classNames from "classnames";

// Icons
import { FiCheck as AttendedIcon } from "react-icons/fi";
import { FiAlertTriangle as MissedIcon } from "react-icons/fi";
import { FiXCircle as CancelIcon } from "react-icons/fi";

// Hooks
import { useCoordoStats } from "contexts/coordo-stats";
import { useTranslation } from "react-i18next";


export function DailyAttendance({ date }) {
    const { stats, loading } = useCoordoStats();
    const { t } = useTranslation();
    const { tutoringAttendance } = stats || {};
    const { dailyAttendance } = tutoringAttendance || {};
    // find the daily attendante with date === date (dailyAttendance is an array of objects with date and attendance)
    // get date in format yyyy-mm-dd
    const formattedDate = new Date(date).toISOString().split('T')[0];
    const data = dailyAttendance?.filter(d => d.date === formattedDate);
    const { attended, missed, cancelled, total } = data?.[0] || {};
    return <>
        <div className="tabular-nums pb-1.5 border-b min-h-[52px] flex flex-col justify-between">
            <div className="flex justify-between items-start">
                <div className=" block leading-none">{t("tutoring")}</div>
                <div className={classNames("leading-none font-semibold mb-1.5 mr-1", !total && "invisible")}>{total || 0}</div>
            </div>
            {
                loading ? <>
                    <Skeleton className="w-full h-5 mt-1" />
                </>
                    :
                    <>

                        <div className="flex items-end justify-between gap-4 w-full">

                            <div>
                                <div className={classNames("text-green-500 font-bold flex items-center gap-1 pb-1 border-t-2 border-transparent", !attended && "opacity-30")}>
                                    {attended || 0} <AttendedIcon className="mt-1" />
                                </div>
                            </div>
                            <div className="flex items-center gap-2">

                                <div className={classNames("text-yellow-600 flex items-center border-yellow-500 border-2 rounded px-1 leading-none pb-1 gap-1", !missed && "hidden")}>
                                    {missed || 0} <MissedIcon className="mt-1" />
                                </div>
                                <div className={classNames("text-red-500 flex items-center gap-1 border-red-500 border-2 rounded px-1 leading-none pb-1", !cancelled && "hidden")}>
                                    {cancelled || 0} <CancelIcon className="mt-1" />
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    </>
}


